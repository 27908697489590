<script setup>
import { ref } from 'vue'
import allScheme from "@/helpers/allScheme";
import stepScheme from "@/helpers/stepScheme";

const activeStep = ref(1)
const activeAnswer = ref(0)

function getNameStep(step) {
  let itemStep = allScheme.find(item => item.id === Number(step))
  if (itemStep !== undefined) {
    return itemStep.name
  }
  return ''
}
function getDayStep(step){
  let itemStep = allScheme.find(item => item.id === Number(step))
  if (itemStep !== undefined) {
    return itemStep.amount
  }
  return 0
}
function getJobDay(step){
  let itemStep = allScheme.find(item => item.id === Number(step))
  if (itemStep !== undefined && itemStep.day === 'work') {
    return false
  }
  return true
}

function change(id, questionId) {
  stepScheme.value = stepScheme.value.map((el) => {
    if (el.id === Number(id)) {
      el.question[questionId].change = !el.question[questionId].change
      let key = Number(questionId) === 1 ? 0 : 1
      el.question[key].change = false
    }
    return el
  })

  activeAnswer.value = questionId
  activeStep.value = ++id
  openStep()
}

async function openStep() {
  stepScheme.value = stepScheme.value.map((el) => {
    // Активный шаг. (click + 1) выбранный вопрос в массиве.
    if (el.id <= activeStep.value) {
      if (el.parent !== undefined) {
        let data = stepScheme.value.find(elem => elem.id === el.parent)
        let link = data.question.find(item => item.id === el.answer)
        if (link.change === true) {
          el.visible = true
        } else {
          el.visible = false
        }
      } else {
        el.visible = true
      }

    }
    if (el.question.length === 0) {
      activeStep.value++
    }
    return el
  })
}

async function getNumberLink(out) {
  if (out.parent !== undefined) {
    let data = stepScheme.value.find(el => el.id === out.parent)
    return data.question[out.answer].change
  }
}


</script>

<template>
  <h1>Порядок государственной регистрации медизделий</h1>
  <div v-for="item in stepScheme" :key="item.id">
    <div :class="['block-scheme', {active: item.visible}]" v-if="getNameStep(item.step)">  <!---->
      {{ getNameStep(item.step) }}
      <span v-if="getDayStep(item.step) !== 0"
            :class="['label', {green:getJobDay(item.step)}]"
      >
        {{ getDayStep(item.step) }}
      </span>
    </div>
    <div :class="['block-scheme_question', {active:item.visible}]"> <!--item.visible-->
      <div v-for="(itemQuestion, key) in item.question" :key="key"
           :class="['block-scheme__item', {activeQuestion: itemQuestion.change}]" @click="change(item.id, key)">
        {{ itemQuestion.name }}
      </div>
    </div>
  </div>
  <div class="comment">
    <div><label class="work">5</label> - рабочие дни</div>
    <div><label class="calendar">10</label> - календарные дни</div>
  </div>
</template>

<style scoped lang="scss">

</style>
