<script setup>
import {ref, onMounted} from 'vue'
import ModalWindow from "@/layouts/ModalWindow.vue";
import ImageBlock from "@/components/block/ImageBlock.vue";
const title = 'О компании'
const shortContent = 'Команда Эвеланс - это не просто коллектив специалистов, это семья, объединенная общей целью - обеспечить безопасность, качество и устойчивость продукции и услуг нашим клиентам.'

const age = ref(0)
const countClient = ref(0)
const modal = ref(false)

function getCountDay(){
  let current = new Date()
  let start = new Date("2024-01-01")
  let diff =current.getTime() - start.getTime()
   return Math.floor(diff / (1000 * 60 * 60 * 24))
}

function getAgeCount(){
  let current = new Date()
  let old = 2012
  return current.getFullYear() - old
}

onMounted(() => {
  countClient.value = getCountDay() * 12
  age.value = getAgeCount()
})

function openModal() {
  modal.value = true
}
function closeModal() {
  modal.value = false
}

</script>

<template>
  <section class="container about">
    <div class="about__intro">
      <div class="undertitle-text about__text">
        <h1 class="page-title">{{ title }}</h1>
        <p>{{ shortContent }}</p>
      </div>
      <img src="/img/about.png"  alt="" srcset="/img/about.png 1x, /img/about@2x.png 2x">
    </div>
    <ul class="about__facts">
      <li class="about__fact">
        <svg width="30" height="30">
          <use xlink:href="#icon-about-1"></use>
        </svg>
        <span>{{ age }} лет</span>
        <p>успешной работы</p>
      </li>
      <li class="about__fact">
        <svg width="30" height="30">
          <use xlink:href="#icon-about-2"></use>
        </svg>
        <span>{{ countClient }} +</span>
        <p>довольных клиентов</p>
      </li>
      <li class="about__fact">
        <svg width="30" height="30">
          <use xlink:href="#icon-about-3"></use>
        </svg>
        <span>3500+ в год</span>
        <p>лабораторных тестов</p>
      </li>
    </ul>
  </section>
  <section class="container history">
<!--    <h2 class="section-title">История компании</h2>-->
    <div class="history__list">
      <p>
        Наша команда Эвеланс - это коллектив высококвалифицированных специалистов в области сертификации, объединенных общей страстью к качеству, безопасности и профессионализму. Каждый член нашей команды обладает уникальным опытом и экспертизой в различных областях, что позволяет нам предоставлять самые высококлассные сертификационные услуги.

        Наш коллектив включает в себя инженеров, аудиторов, специалистов по стандартизации и регулированию, а также консультантов, обладающих обширным практическим опытом. Мы постоянно совершенствуем наши знания и навыки, чтобы быть в курсе последних изменений в стандартах и требованиях.
      </p>
    </div>
  </section>
  <section class="container official-partner">
    <div class="official-partner__content">
      <img src="/img/official-partner.png" width="213" height="185" alt="" srcset="/img/official-partner.png 1x, /img/official-partner.png 2x">
      <div class="official-partner__text">
        <h2 class="section-title">Официальный партнер Центра по внешней торговле при Минпромторге России</h2>
          <p>Наше партнерство основано на общих ценностях инноваций, качества и удовлетворенности клиентов.</p>

          <p>Мы уверены, что это партнерство принесет множество преимуществ как нашей компании, так и нашим клиентам,
            и мы с нетерпением ждем возможности расширить наше сотрудничество в будущем.</p>
      </div>
    </div>
  </section>
  <section class="container accreditations">
    <h2 class="section-title">Наши аккредитации</h2>
    <ul class="accreditations__list">
      <li>
        <p>Аттестат государственной аккредитации <br> RA.RU.11АЖ06</p>
        <a @click="openModal">ООО Гринлайн</a>
      </li>
    </ul>
  </section>
  <section class="container advantages advantages--alt">
    <h2 class="section-title">Это про нас:</h2>
    <ul class="advantages__list">
      <li class="advantages__item">
        <svg width="60" height="60">
          <use xlink:href="#advantage-6"></use>
        </svg>
        <div>
          <h3>Лучшие тарифы на&nbsp;транспортировку товара из&nbsp;Китая и Индии</h3>
<!--          <a href="transportation.html">Подробнее</a>-->
        </div>
      </li>
      <li class="advantages__item">
        <svg width="60" height="60">
          <use xlink:href="#advantage-4"></use>
        </svg>
        <div>
          <h3>Собственный отдел технических специалистов по регистрации медицинских изделий</h3>
<!--          <a href="registration.html">Перейти в раздел</a>-->
        </div>
      </li>
      <li class="advantages__item">
        <svg width="60" height="60">
          <use xlink:href="#advantage-2"></use>
        </svg>
        <div>
          <h3>Аккредитованная испытательная лаборатория ТСЛ</h3>
          <a href="/about/ral">О лаборатории</a>
        </div>
      </li>
    </ul>
  </section>
  <ModalWindow :open="modal" @close="closeModal">
    <ImageBlock :item="{name:'green.webp', path:'ral'}"/>
  </ModalWindow>
</template>

<style scoped lang="scss">
  .history__list p{
    font-size: 22px;
    line-height: 28px;
  }
</style>
