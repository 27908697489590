<script setup>

</script>

<template>
  <div class="container banner-block">
    <a href="/about/cargo">
    <img src="/img/cargo.png" />
    </a>
  </div>
</template>

<style scoped lang="scss">
  .banner-block {
    width: 100%;
  }
</style>
