<script setup>

import { inject, defineProps, computed } from "vue";



const props = defineProps({
  navMenu: Array
})

const menuAbout = computed(() => {
    return props.navMenu.filter((item) => item.alias === 'about')
})

const menuService = computed(() => {
  const section = props.navMenu.filter((item) => item.alias === 'section')
  if(section[0]){
      return section[0].items
  }
  return []
})

const modal = inject('stateModal')
function openFeedback(){
  modal.value = true
}


</script>

<template>
  <footer class="footer">
    <div class="container">
      <div class="footer__nav">
        <RouterLink to="/" class="footer__logo">
          <img src="/img/footer-logo.png" width="122" height="150" alt="Логотип компании" srcset="/img/footer-logo.png 1x, /img/footer-logo@2x.png 2x">
        </RouterLink>
        <ul class="footer__menu">
          <li class="footer__menu-item" v-for="itemAbout in menuAbout" :key="itemAbout.id">
            <RouterLink v-if="itemAbout.items.length === 0" :to="itemAbout.alias">{{itemAbout.name}}</RouterLink>
            <span style="padding-bottom: 10px" v-else>{{ itemAbout.name }}</span>
            <ul>
              <li v-for="itemSubmenu in itemAbout.items" :key="itemSubmenu.id">
                <RouterLink :to="'/' + itemAbout.alias + '/'+ itemSubmenu.alias">{{ itemSubmenu.name }}</RouterLink>
              </li>
            </ul>
          </li>
        </ul>
        <ul class="footer__menu">
<!--          <li class="footer__menu-item">
            <RouterLink to="/blog">Блог</RouterLink>
          </li>-->
          <li class="footer__menu-item">
            <a @click="openFeedback" data-modal-open="contact-us">Обратная связь</a>
          </li>
        </ul>

        <div class="footer__contacts">
          <div class="footer__contacts-phones">
            <a :href="'tel:' + basicPhone">{{ basicPhone }}</a>
          </div>
          <p class="footer__contacts-address"> {{ basicEmail }}</p>
          <ul class="footer__socials">
            <li>
              <a href="#">
                <svg width="20" height="20" fill="white">
                  <use xlink:href="#icon-skype"></use>
                </svg>
              </a>
            </li>
            <li>
              <a href="https://t.me/evelans_group">
                <svg width="20" height="20" fill="white">
                  <use xlink:href="#icon-telegram-footer"></use>
                </svg>
              </a>
            </li>
            <li>
              <a href="#">
                <svg width="20" height="20" fill="white">
                  <use xlink:href="#icon-whatsapp"></use>
                </svg>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="footer__cert" v-for="itemService in menuService" :key="itemService.id">
        <RouterLink :to="'/section/'+ itemService.alias" class="footer__submenu-title">
          {{ itemService.name }}
          <svg width="20" height="20">
            <use xlink:href="#icon-plus"></use>
          </svg>
        </RouterLink>
        <ul class="footer__submenu-list">
          <li>
            <ul>
              <li v-for="itemSubService in itemService.items" :key="itemSubService.id">
                <RouterLink :to="'/section/'+ itemService.alias + '/' + itemSubService.alias">{{ itemSubService.name }}</RouterLink>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="footer__bottom">
        <p>Все права защищены</p>
      </div>
    </div>
  </footer>
</template>

<style scoped lang="scss">
.footer__submenu-list{
  column-count: unset;
}
.footer__submenu-list li ul{
  //flex-direction: row;
}
.footer__submenu-list li{
  break-inside: unset;
}
</style>
