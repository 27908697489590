<script setup>
import {computed, onMounted, provide, ref} from 'vue'
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import LandingLayout from "@/layouts/LandingLayout.vue";
import {useRoute} from "vue-router";


const dataModal = ref({
  name: '',
  utm: ''
})
const route = useRoute()
const value = ref(false)
const valueDone = ref(false)

provide('stateModal', value)
provide('stateDone', valueDone)

const layout = computed(() => {
  return route.meta.layout ?? 'DefaultLayout'
})

</script>

<template>
  <LandingLayout v-if="route.meta.layout"/>
  <DefaultLayout v-else/>
</template>

<style lang="scss">

</style>
