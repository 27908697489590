<script setup>
import {ref, defineProps, watch, inject, onMounted} from 'vue'
import Swiper from "swiper";
import {Navigation} from "swiper/modules";

const props = defineProps({
  ral: {
    type: Object
  },
  showRal: Boolean
})

const modal = inject('stateModal')

function openModal() {
  modal.value = true
}

onMounted(async () => {
  goSwiper()
})

function fullPath(name) {
  return process.env.VUE_APP_URL_SITE + 'images/info/' + name
}

function goSwiper() {
  const sliderContainer = document.querySelector('.info-ral-container');
  if (sliderContainer) {
    const prevBtn = sliderContainer.querySelector('.slider-btn--prev');
    const nextBtn = sliderContainer.querySelector('.slider-btn--next');
    new Swiper(sliderContainer, {
      slidesPerView: 1,
      spaceBetween: 60,
      direction: 'horizontal',
      modules: [Navigation],
      navigation: {
        nextEl: nextBtn,
        prevEl: prevBtn,
      },
    });
  }
}

</script>

<template>
  <div v-show="props.showRal">
    <section class="container laboratory">
      <h1 class="page-title">{{ props.ral.name }}</h1>
      <div class="undertitle-text">
        <p>{{ props.ral.content }}</p>
        <button @click="openModal" class="button button--dark-blue" data-modal-open="contact-us">Заказать исследование
        </button>
      </div>
      <div class="laboratory__objects">
        <h2 class="section-title">Объекты испытаний</h2>
        <ul class="laboratory__objects-list">
          <li v-for="itemTest in  props.ral.testObject" :key="itemTest.id">
            <svg width="50" height="50">
              <use :xlink:href="itemTest.img"></use>
            </svg>
            <h3>{{ itemTest.name }}</h3>
          </li>
        </ul>
      </div>
    </section>

    <section class="container equipment swiper-container info-ral-container">
      <h2 class="section-title">О лаборатории</h2>
      <div class="slider-controls">
        <a class="slider-btn slider-btn--prev swiper-button-prev">
          <svg width="26" height="26">
            <use xlink:href="#icon-arrow"></use>
          </svg>
        </a>
        <a class="slider-btn slider-btn--next swiper-button-next">
          <svg width="26" height="26">
            <use xlink:href="#icon-arrow"></use>
          </svg>
        </a>
      </div>
      <div class="swiper-wrapper" style="display: flex">
        <div class="equipment__content swiper-slide" v-for="(item) in props.ral.addInfo" :key="item.id">
          <img :src="fullPath(item.img)" width="313" alt="Фото оборудования">
          <div class="equipment__text" v-html="item.content">
          </div>
        </div>
      </div>
    </section>
    <section class="container accreditations" v-if="false">
      <h2 class="section-title">Сертификаты лаборатории</h2>
      <ul class="accreditations__list">
        <li>
          <p>Аттестат государственной аккредитации <br> RA.RU.10АД57 20.03.2017</p>
          <a href="#">Подробнее</a>
        </li>
        <li>
          <p>Лицензия Ростехнадзора <br> № ДЭ-00-016638 от 7 июня 2017 года</p>
          <a href="#">Подробнее</a>
        </li>
      </ul>
    </section>
  </div>
</template>

<style scoped lang="scss">
.undertitle-text p {
  max-width: 1200px;
}

.info-ral-container {
  display: flex;
  overflow: hidden;
  flex-direction: column;
}

.equipment .section-title {
  margin-bottom: 10px;
}
.equipment__content{
  flex-shrink: 0;
}

</style>
