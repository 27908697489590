<script setup>
import {inject, ref} from "vue";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import ModalWindow from "@/layouts/ModalWindow.vue";
import CardBlock from "@/components/block/CardBlock.vue";

const outLinks = ref([
  {
    name: 'Контакты',
    href: 'contact'
  }
])

const modal = inject('stateModal')
const cardModal = ref(false)
function openModal(){
  modal.value = {
    state: true,
    name: 'Раздел контакты'
  }
}
function openCard(){
    cardModal.value = true
}
function closeCard(){
  cardModal.value = false
}


</script>

<template>
  <section class="contacts">
    <Breadcrumbs :out-links="outLinks" />
    <div class="container contacts__container">
      <h1 class="page-title">Контакты</h1>
      <ul class="contacts__list">
        <li class="contacts__item">
          <svg width="50" height="50">
            <use xlink:href="#icon-location"></use>
          </svg>
          <div>
            <p> {{ $t('address')  }}</p>
          </div>
          <a @click="openCard">Показать на карте</a>
        </li>
        <li class="contacts__item">
          <svg width="50" height="50">
            <use xlink:href="#icon-phone"></use>
          </svg>
          <div>
            <a :href="'tel:'+ formatBasicPhone "> {{ basicPhone }} </a>
          </div>
<!--          <a href="#" data-modal-open="contact-us">Заказать звонок</a>-->
        </li>
        <li class="contacts__item">
          <svg width="50" height="50">
            <use xlink:href="#icon-mail"></use>
          </svg>
          <div>
            <a :href="'mailto:'+ basicEmail "> {{ basicEmail }}</a>
          </div>
          <a @click="openModal">Написать нам</a>
        </li>
      </ul>
    </div>
  </section>
  <ModalWindow :open="cardModal" @close="closeCard">
      <CardBlock></CardBlock>
  </ModalWindow>
</template>

<style scoped lang="scss">

</style>
