const tokenService = {
  save(value) {
    localStorage.setItem('token_eva', value)
  },
  read() {
    return localStorage.getItem('token_eva')
  }
}

export default tokenService
