<script setup>
import {ref, onMounted} from "vue";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import ContentBlock from "@/components/block/ContentBlock.vue";
import blogService from "@/services/blog.service";
import {useRoute} from "vue-router";
import ImageBlock from "@/components/block/ImageBlock.vue";
import QuoteBlock from "@/components/block/QuoteBlock.vue";
import VideoBlock from "@/components/block/VideoBlock.vue";


const route = useRoute()

function getId() {
  let array = route.path.split('/')
  return array[2]
}

const loadPost = async () => blogService.one(getId())
const blogPost = ref({})
const outLinks = ref([
  {
    name: 'Блог',
    href: '/blog'
  },
  {
    name: 'Props',
    href: 'props.href'
  }
])

onMounted(async () => {
  blogPost.value = await loadPost()
})

function changeComponent(type){
  if(type === 1){
    return ContentBlock
  }
  else if (type === 2){
    return ImageBlock
  }
  else if(type === 3){
    return VideoBlock
  }
  else if(type === 4){
    return QuoteBlock
  }
}

</script>

<template>
  <section class="news">
    <Breadcrumbs :out-links="outLinks"/>
    <div class="container news__container">
      <h1 class="page-title"> {{ blogPost.name }} </h1>
      <span class="news__date"> {{ blogPost.created_at }} </span>
      <component
           v-for="(item, key) in blogPost.allContent" :key="key"
           :is="changeComponent(item.type_id)"
           :item="item"
      >
      </component>
    </div>
  </section>
</template>

<style scoped lang="scss">

</style>
