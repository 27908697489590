<script setup>
import { ref, defineProps, defineEmits } from 'vue'
import ShemeBlock from "@/components/scheme/SchemeBlock.vue";

const props = defineProps({
  open:{
    type: Boolean
  }
})

const emits = defineEmits([
  'close'
])

const stateModal = ref(false)

function closeModal(){
  emits("close")
}
</script>

<template>
  <div :class="['modal', 'modal--contact-us', {'is-active': props.open}]" data-modal="contact-us">
    <div class="modal__wrapper">
      <div class="modal__overlay" data-close-modal></div>
      <div class="modal__content">
        <button @click="closeModal" class="modal__close-btn btn-reset" type="button" aria-label="Закрыть попап" data-close-modal>
          <svg width="27" height="27">
            <use href="#icon-cross"></use>
          </svg>
        </button>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@media (max-width: 420px) {
  .modal__wrapper{
    padding: 20px 10px;
  }
  .modal__content{
    padding: 15px;
  }
}
</style>
