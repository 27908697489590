import axios from "axios";

const sectionService = {
  async oneDocument(alias) {
    const {status, data} = await axios(process.env.VUE_APP_URL_API + 'product/one', {
      method: 'POST',
      data: {slug: alias},
      headers: {
        Origin: 'https://evelans.ru',
        'User-Agent': ' curl/7.51.0',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PATCH,OPTIONS',
        'Access-Control-Allow-Headers': 'Origin, Accept, Content-Type, Access-Control-Allow-Origin',
        'Content-Type': 'application/json; charset=UTF-8'
      }
    })

    if (status === 200) {
      return data
    } else {
      return false
    }
  },

  async oneSection(alias) {

    const {status, data} = await axios(process.env.VUE_APP_URL_API + 'section/one', {
      method: 'POST',
      data: {slug: alias},
      headers: {
        Origin: 'https://evelans.ru',
        'User-Agent': ' curl/7.51.0',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PATCH,OPTIONS',
        'Access-Control-Allow-Headers': 'Origin, Accept, Content-Type, Access-Control-Allow-Origin',
        'Content-Type': 'application/json; charset=UTF-8'
      }
    })

    if (status === 200) {
      return data
    } else {
      return false
    }
  }
}

export default sectionService
