const covidScheme = [
  {
    id: 1,
    name: 'Разработка проектов документов регистрационного досье',
    amount: 15,
    day: 'work'
  },
  {
    id: 2,
    name: 'Технические, токсикологические, клинические испытания',
    amount: 30,
    day: 'work'
  },
  {
    id: 3,
    name: 'Формирование регистрационного досье',
    amount: 1,
    day: 'work'
  },
  {
    id: 4,
    name: 'Подача документов в экспертную организацию',
    amount: 0,
    day: 'work'
  },{
    id: 5,
    name: 'Рассмотрение документов экспертной организацией',
    amount: 3,
    day: 'work'
  },
  {
    id: 6,
    name: 'Направление заключения о возможности регистрации',
    amount: 0,
    day: 'work'
  },
  {
    id: 7,
    name: 'Принятие решения о государственной регистрации',
    amount: 2,
    day: 'work'
  },
  /*{
    id: 8,
    name: '',
    amount: 15,
    day: 'work'
  },
  {
    id: 9,
    name: '',
    amount: 15,
    day: 'work'
  },
  {
    id: 10,
    name: '',
    amount: 15,
    day: 'work'
  },*/
]

const step = [
  {
    id: 1,
    step: 1,
    visible: true
  },
  {
    id: 2,
    step: 2,
    visible: true
  },
  {
    id: 3,
    step: 3,
    visible: true
  },
  {
    id: 4,
    step: 4,
    visible: true
  },
  {
    id: 5,
    step: 5,
    visible: true
  },
  {
    id: 6,
    step: 6,
    visible: true
  },
  {
    id: 7,
    step: 7,
    visible: true
  },
]

export { covidScheme, step }
