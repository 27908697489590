<script setup>
import { defineProps } from 'vue'

const props = defineProps({
  item: Object
})

</script>

<template>
 <div class="news__description" v-html="props.item.content">
 </div>
</template>

<style scoped lang="scss">

</style>
