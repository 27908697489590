<script setup>

import {inject} from "vue";

const stateDone = inject('stateDone')

function closeModal(){
    stateDone.value = false
}

</script>

<template>
  <div :class="['modal', 'modal--contact-us' , {'is-active': stateDone}]">
    <div class="modal__wrapper">
      <div class="modal__overlay" data-close-modal></div>
      <div class="modal__content">
        <h3 class="page-title">Ваша заявка принята, в ближайшее время мы с вами свяжемся</h3>
        <button @click="closeModal" class="modal__close-btn btn-reset" type="button" aria-label="Закрыть попап" data-close-modal>
          <svg width="27" height="27">
            <use href="#icon-cross"></use>
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
