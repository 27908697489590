<script setup>
import {onMounted, ref, inject, defineProps, watch} from 'vue'
import { useYandexMetrika } from 'yandex-metrika-vue3'
import {useI18n} from "vue-i18n";
import utmService from "@/services/utm.service";
import {useRoute, useRouter} from "vue-router";
import IconSvg from "@/components/ui/IconSvg.vue";

const { t, locale } = useI18n({useScope: "global"})

const router = useRouter()
const route = useRoute()

const utmData = utmService.read()

onMounted(async () => {
  await router.isReady()
  saveUtm()
})

const yandexMetrika = useYandexMetrika()

const modal = inject('stateModal')

const visiblePhone = ref(false)
function hiddenPhone(){
    visiblePhone.value = true
}

function openFeedback() {
  yandexMetrika.reachGoal('openForm')
  modal.value = {
    state: true,
    name: 'Обратная связь'
  }
}

function sendTG(){
  yandexMetrika.reachGoal('openTG')
}
function getClass(slug) {
  if (slug === 'certificate') {
    return 'header__submenu-cert'
  } else if (slug === 'registry') {
    return 'header__submenu-reg'
  } else {
    return 'header__submenu-about'
  }
}
function getUrl(item) {
  if (item.items.length > 0) {
    return '#'
  }
  return '/' + item.alias
}

function switchLang() {
  locale.value = locale.value === 'ru'? locale.value = 'ch' : locale.value = 'ru'
  localStorage.setItem('lang', locale.value)
}
function visibleBlock(){
    if(process.env.NODE_ENV === 'production'){
        return false
    }
    return true
}

watch(locale, (newValue, oldValue) => {
    location.reload()
})

function saveUtm() {
  if (!utmData || Object.keys(utmData).length === 0) {
    utmService.save(route.query)
  }
}

</script>

<template>
  <suspense>
    <template #default>
  <header :class="['header', {'nav-opened':mobMenu}]">
    <div class="container header__container">
        <img src="/img/header-logo.png" width="140" height="15" alt="Логотип компании"
             srcset="/img/header-logo.png 1x, /img/header-logo@2x.png 2x">
      <nav class="header__nav">
        <ul class="header__menu">
          <li class="header__menu-item">
            <a @mouseover="subMenu=0"
               @click="openFeedback"
               class="header__menu-link"
               data-modal-open="contact-us">Обратная связь</a></li>
        </ul>
        <ul class="header__contacts">
          <li class="header__phone">
            <a title="Показать телефон" @click="hiddenPhone">
            <IconSvg size="35" icon="icon-phone" fill="#6388A8"/>
            </a>
          </li>
          <li class="header__phone" v-if="visiblePhone"><a :href="'tel:' + basicPhone">{{ basicPhone }}</a></li>
          <li class="header__socials">
            <a :href="tmBot" @click="sendTG" title="Написать в телеграм">
              <IconSvg size="35" icon="icon-telegram" fill="#6388A8"/>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </header>
    </template>
    <template #fallback>
      <div>Загрузка информации, чуть-чуть подождите</div>
    </template>
  </suspense>
</template>

<style scoped lang="scss">
  .header__menu-item{
      border-left: 1px #d5d4d4 solid;
      margin: 0;
      padding: 0 25px;
  }
  .header__submenu{
      padding-top: 30px;
  }
  @media (max-width: 980px) {
      .header__container{
          justify-content: space-between;
      }
      .header__phone {
        display: flex;
      }
      .header__socials{
        display: none;
      }
      .header__menu{
        display: none;
      }
  }
  @media(max-width: 767px){
    .header__nav {
        display: flex;
        width: unset;
    }
    .header {
      padding: unset;
    }
  }
</style>
