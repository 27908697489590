<script setup>
import { defineProps } from 'vue'

const props = defineProps({
  item: Object
})

function fullPatch(name){
  let path = ''
  if(props.item.path === undefined){
      path = 'blog'
  }else{
    path = props.item.path
  }

  return process.env.VUE_APP_URL_SITE + 'images/'+ path +'/' + name
}
</script>

<template>
  <div class="news__description">
    <img :src="fullPatch(props.item.name)"
         alt="">
  </div>
</template>

<style scoped lang="scss">
  .news__description{
      text-align: center;
  }
  .news__description img{
      max-width: 600px;
  }
</style>
