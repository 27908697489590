<script setup>
import { defineProps } from 'vue'

const props = defineProps({
  item: Object
})
</script>

<template>
  <div class="news__description">
    <div class="news__quote" v-html="props.item.body">
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
