<script setup>

import {inject, ref} from "vue";
import {useYandexMetrika} from "yandex-metrika-vue3";


const yandexMetrika = useYandexMetrika()
const modal = inject('stateModal')
function openModal(){
  yandexMetrika.reachGoal('openForm')

  modal.value = {
    state: true,
    name: 'Титул, консультация'
  }
}

const text = ref(defText)
const defText = 'Компания Эвеланс специализируется на сертификации продукции и регистрации медицинских изделий, обеспечивая высокое качество услуг и профессиональное сопровождение на всех этапах процесса. Мы ценим ваше время и стремимся к превосходству.'
const sertText = 'Сертификация продукции - это процесс оценки и подтверждения соответствия продукции установленным стандартам, требованиям и спецификациям. Получение сертификата подтверждает, что продукция соответствует установленным стандартам и требованиям.'
const registryText = 'Регистрация медицинских изделий - это процесс оценки и подтверждения соответствия продукции установленным стандартам, требованиям и спецификациям. Получение сертификата подтверждает, что продукция соответствует установленным стандартам и требованиям.'

function activeText(id){
  if(id === 1){
    text.value = sertText
  }
  if(id === 2){
    text.value = registryText
  }
}
function defaultText(){
    text.value = defText
}


</script>

<template>
  <section class="services">
    <h2 class="visually-hidden">Услуги</h2>
    <div class="container services__container">
      <ul class="services__list">
        <li class="services__item"
            @mouseover="activeText(1)"
            @mouseout="defaultText()"
        >
          <RouterLink to="/section/certificate">
            <p>Сертификация продукции</p>
          </RouterLink>
          <p class="services__item-text"></p>
        </li>
        <li class="services__item"
            @mouseover="activeText(2)"
            @mouseout="defaultText()"
        >
          <RouterLink to="section/registry">
            <p>Регистрация медицинских изделий</p>
          </RouterLink>
          <p class="services__item-text"></p>
        </li>
      </ul>
      <div class="services__description">
        <p class="services__description-text">{{ text }}</p>
        <button @click="openModal" class="button button--blue" data-modal-open="contact-us">консультация</button>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">

</style>
