<script setup>
import Breadcrumbs from "@/components/Breadcrumbs";
import {useRoute} from "vue-router";
import {inject, onMounted, ref, watch} from "vue";
import sectionService from "@/services/section.service";

const route = useRoute()
const outLinks = ref([])

const section = ref(false)
const loadSection = async () => await sectionService.oneSection('section')


async function loadOutLinks() {
  return [
    {
      name: 'Заказ документов',
      href: '/service'
    }
  ]
}

onMounted(async () => {
  section.value = await loadSection()
  outLinks.value = await loadOutLinks()
})
watch(() => route.path, async () => {
  section.value = await loadSection()
  outLinks.value = await loadOutLinks()
})

const modal = inject('stateModal')
function openModal(){
  modal.value = {
    state: true,
    name: section.value.name
  }
}
</script>

<template>
  <Breadcrumbs :outLinks="outLinks"/>
  <section class="container certification">
    <h1 class="page-title">{{ section.name }}</h1>
    <div class="undertitle-text" style="text-align: right">
      <button @click="openModal" class="button button--dark-blue" data-modal-open="contact-us">Консультация</button>
    </div>
    <div>
      <div class="certification__group" v-for="itemGroup in section.items" :key="itemGroup.id">
        <h3 class="section-title"> {{ itemGroup.name }}
<!--          <button>
            <svg width="26" height="26">
              <use xlink:href="#icon-arrow"></use>
            </svg>
          </button>-->
        </h3>
        <ul class="certification__list">
          <li v-for="item in itemGroup.items" :key="item.id">
            <RouterLink :to="'/section/'+ section.alias + '/' + item.alias">
              <p>{{ item.name }}</p>
            </RouterLink>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
  .certification__list{
      display: flex;
  }
</style>
