const utmService = {
  save(value) {
    localStorage.setItem('utm_eva', JSON.stringify(value))
  },
  read() {
    return JSON.parse(localStorage.getItem('utm_eva'))
  }
}

export default utmService
