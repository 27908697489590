<script setup>

import HeaderLanding from "@/layouts/part/HeaderLanding.vue";
import SvgBlock from "@/layouts/SvgBlock.vue";
import FooterLanding from "@/layouts/part/FooterLanding.vue";
import OrderModal from "@/layouts/modal/OrderModal.vue";
import {onMounted, ref} from "vue";
import ModalForm from "@/layouts/ModalForm.vue";
import {useRoute} from "vue-router";
import DoneModal from "@/layouts/modal/DoneModal.vue";
import PresentModal from "@/layouts/modal/PresentModal.vue";
import QuizModal from "@/layouts/modal/QuizModal.vue";
import {useYandexMetrika} from "yandex-metrika-vue3";


const yandexMetrika = useYandexMetrika()
const visibleModal = ref(false)
const visibleQuiz = ref(false)
const presentVisible = ref(false)

const doc = ref('')

function openModal(){
  if(doc.value === 'ru'){
    visibleModal.value = true;
  }else{
    visibleQuiz.value = true
  }
}
function closeModal(){
  visibleModal.value = false;
  presentVisible.value = false
  visibleQuiz.value = false
}

const point = ref(5)
function moveMouse($event){
  if($event.clientY > point.value){
    point.value = $event.clientY;
  }

  if($event.clientY < 5 && point.value > 200){
    openPresentModal()
    sessionStorage.setItem("present", true)

  }
}
function openPresentModal(){
  yandexMetrika.reachGoal('showOut')
  if(sessionStorage.getItem('present') === 'false'){
    presentVisible.value = true
  }
}

function leaveMouse($event){
  //  visibleModal.value = true;
}

function setDoc(val) {
  doc.value = val
}

onMounted(() => {
    sessionStorage.setItem("present", false)
})

</script>

<template>
  <SvgBlock/>
  <div class="wrapper" @mousemove="moveMouse" @mouseleave="leaveMouse">
    <HeaderLanding />
    <main class="main">
      <router-view  @open="openModal" @doc="setDoc"/>
    </main>
    <FooterLanding />
    <OrderModal :visibleModal="visibleModal" :doc="doc"  @close="closeModal"/>
    <PresentModal :visibleModal="presentVisible" :doc="doc"  @close="closeModal"/> />
    <ModalForm />
    <QuizModal :visible-modal="visibleQuiz" :doc="doc" @close="closeModal"/>
    <DoneModal />
  </div>
</template>

<style scoped lang="scss">

</style>
