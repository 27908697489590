import axios from "axios";

const blogService = {
  async preview() {
    const {status, data} = await axios(process.env.VUE_APP_URL_API + 'blog/index-tag', {
      method: 'GET',
      headers: {
        Origin: 'https://evelans.ru',
        'User-Agent': ' curl/7.51.0',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PATCH,OPTIONS',
        'Access-Control-Allow-Headers': 'Origin, Accept, Content-Type, Access-Control-Allow-Origin',
        'Content-Type': 'application/json; charset=UTF-8'
      }
    })
    if (status === 200) {
      return data
    } else {
      return []
    }
  },

  async list(tag = null) {
    const {status, data} = await axios(process.env.VUE_APP_URL_API + 'blog/index', {
      method: 'POST',
      data: {tag: tag},
      headers: {
        Origin: 'https://evelans.ru',
        'User-Agent': ' curl/7.51.0',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PATCH,OPTIONS',
        'Access-Control-Allow-Headers': 'Origin, Accept, Content-Type, Access-Control-Allow-Origin',
        'Content-Type': 'application/json; charset=UTF-8'
      }
    })
    if (status === 200) {
      return data
    } else {
      return []
    }
  },

  async one(id){
      const {data, status} = await axios(process.env.VUE_APP_URL_API + 'blog/' + id, {
        method: 'GET',
        headers: {
          Origin: 'https://evelans.ru',
          'User-Agent': ' curl/7.51.0',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET,PATCH,OPTIONS',
          'Access-Control-Allow-Headers': 'Origin, Accept, Content-Type, Access-Control-Allow-Origin',
          'Content-Type': 'application/json; charset=UTF-8'
        }
      })
    if (status === 200) {
      return data
    } else {
      return {}
    }
  }

}

export default blogService
