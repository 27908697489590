<script setup>
import {onMounted, ref} from 'vue'
import publishService from "@/services/publish.service";
import {useRoute} from "vue-router";

const route = useRoute()
const loadPublish = async () => await publishService.goTimeline(route.params.slug)

const publishJob = ref('')

onMounted(async () => {
  publishJob.value = await loadPublish()
})

function dateFormat(date) {
  let newDate = new Date(date);
  return newDate.toLocaleString("ru")
}


</script>

<template>
  <div class="container">
    <div class="error-field" v-if="publishJob === undefined">
      Данных по Вашему запросу не нашлось. Перезапросите ссылку у менеджера
    </div>
    <div class="done-publish" v-if="publishJob">
      <h2>Этапы выполненных работ:</h2>
      <h3>{{ publishJob.publish_job.title }}</h3>

      <div class="stage__block">
        <ul>
          <li v-for="(item, index ) in publishJob.publish_job.stage" :key="item.id">
            {{ item.name }} /
            <span v-if="index === publishJob.publish_job.stage.length - 1 ">
                  {{ dateFormat(publishJob.publish_job.movedTime) }}
                </span>
          </li>
        </ul>
      </div>


    </div>
  </div>
</template>

<style scoped lang="scss">
.error-field {
  margin: 20px;
  padding: 20px;
  font-size: 20px;
  border: 1px red solid;
  border-radius: 20px;
}
</style>
