<script setup>
import {defineProps, watch, ref, onMounted} from 'vue'
import Swiper from "swiper";
import {Navigation} from "swiper/modules";
import ModalWindow from "@/layouts/ModalWindow.vue";
import ImageBlock from "@/components/block/ImageBlock.vue";

const props = defineProps({
  reference: Array
})

const newReference = ref([])

onMounted(async () => {
  newReference.value = await props.reference
  await goSwipper()
})

function fullPath(name) {
  return process.env.VUE_APP_URL_SITE + 'images/reference/' + name
}

async function goSwipper() {
  if (document.body.clientWidth > '767') {
    const teamSliderContainer = document.querySelector('.slider-container');
    if (teamSliderContainer) {
      const prevBtn = teamSliderContainer.querySelector('.slider-btn--prev');
      const nextBtn = teamSliderContainer.querySelector('.slider-btn--next');
      new Swiper(teamSliderContainer, {
        slidesPerView: 1,
        spaceBetween: 0,
        modules: [Navigation],
        direction: 'horizontal',

        navigation: {
          nextEl: nextBtn,
          prevEl: prevBtn,
        },

        breakpoints: {

          768: {
            slidesPerView: 2,
            spaceBetween: 25,
          },

          900: {
            slidesPerView: 3,
            spaceBetween: 25,
          },

          1024: {
            slidesPerView: 4,
            spaceBetween: 25,
          }
        },
      });
    }
  }
}

const showModal = ref(false)
const showImg = ref('')

function openModal(img) {
  showModal.value = true
  showImg.value = img
}

function closeModal() {
  showModal.value = false
  showImg.value = ''
}

</script>

<template>
  <div class="container slider-container swiper-container">
    <h2 v-show="props.reference.length > 0">Нас рекомендуют</h2>
    <ul class="team__slider swiper-wrapper">
      <li @click="openModal(item.img)" class="team__item swiper-slide" v-for="item in props.reference" :key="item.id">
        <img :src="fullPath(item.img)" width="290px">
      </li>
    </ul>
  </div>
  <ModalWindow :open="showModal" @close="closeModal">
    <ImageBlock :item="{name: showImg, path:'reference'}"/>
  </ModalWindow>
</template>

<style scoped lang="scss">

</style>
