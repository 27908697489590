<script setup>
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import {computed, ref} from 'vue'
import {useVuelidate} from '@vuelidate/core'
import {required, helpers,} from '@vuelidate/validators'
import authService from "@/services/auth.service";
import {useRouter} from "vue-router";
import tokenService from "@/services/token.service";

const router = useRouter()

const form = ref({
  login: '',
  password: ''
})

const validations = computed(() => {
  return {
    login: {
      required: helpers.withMessage('Обязательно к заполнению', required),
    },
    password: {
      required: helpers.withMessage('Обязательно к заполнению', required),
    }
  }
})

const v$ = useVuelidate(validations, form)

const errorMessage = ref('')

async function sendForm() {
  const isFormCorrect = await v$.value.$validate()

  if (!isFormCorrect) return

  const result = await authService.login(form.value)
  if (result.access_token) {
    errorMessage.value = ''
    await tokenService.save(result.access_token)
    await router.push({name:'personal'})
  }
  if (result.error) {
    errorMessage.value = result.error
  }

}

</script>

<template>
  <section class="container">
    <Breadcrumbs :out-links="[{name:'Авторизация',href:'auth'}]"/>
    <div class="login">
      <h3 class="page-title">Авторизация в личном кабинете</h3>
      <form v-on:submit.prevent="sendForm" class="form modal__form">
        <div class="form__fields-wrapper">
          <label for="" :class="['form__field', {'error-field': form.login.length === 0 }]">
            <input type="text" class="filled" v-model="form.login">
            <span class="form__field-placeholder">Логин или эл.почта</span>
            <span v-if="v$.login.$dirty && v$.login.required.$invalid" class="error-field__text">
                 {{ v$.login.required.$message }}
              </span>
          </label>
          <label for="" class="form__field" :class="['form__field', {'error-field': form.password.length === 0 }]">
            <input type="password" v-model="form.password">
            <span class="form__field-placeholder">Пароль</span>
            <span v-if="v$.password.$dirty && v$.password.required.$invalid" class="error-field__text">
                 {{ v$.password.required.$message }}
              </span>
          </label>
        </div>
        <div class="error-field__message">{{ errorMessage }}</div>
        <div class="in_reg">
          <span>Регистрация нового пользователя</span>
        </div>
        <div class="form__buttons">
          <button class="button button--dark-blue" data-close-modal type="submit">Отправить</button>
          <p>Нажимая эту кнопку Вы соглашаетесь с <a href="#">условиями обработки информации</a></p>
        </div>
      </form>
    </div>

  </section>

</template>

<style scoped lang="scss">
.login {
  margin: 20px 5px;
}
.error-field__message{
    width: 100%;
    text-align: center;
    font-size: 20px;
    color: red;

}
.in_reg {
    text-align: right;
    span{
      padding: 15px 20px;
      background-color: #6388a8;
      border-radius: 20px;
      color: white;
      cursor: pointer;
    }
}
</style>
