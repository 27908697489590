import { ref } from 'vue'
const stepScheme = ref([
  {
    id: 1,
    visible: true,
    question: [
      {
        id:1,
        name: 'Импортное изделие',
        change: false
      },
      {
        id:2,
        name: 'Отечественное изделие',
        change: false
      }
    ],
    step: 1
  },
  {
    id: 2,
    visible: false,
    question:[],
    step: 2,
    parent: 1,
    answer: 1
  },
  {
    id: 3,
    visible: false,
    question:[
      {
        id:1,
        name: 'Изделия, клинические испытания которых проводят в форме анализа и оценки данных, ' +
          'клинико-лабораторных испытаний',
        change: false
      },
      {
        id:2,
        name: 'Изделия, испытания которых предусматривают клинические испытания с участием человека',
        change: false
      }
    ],
  },
  {
    id: 4,
    visible: false,
    question:[],
    step: 3,
    parent: 3,
    answer: 1
  },
  {
    id: 5,
    visible: false,
    question:[],
    step: 4,
    parent: 3,
    answer: 2
  },
  {
    id: 6,
    visible: false,
    question:[],
    step: 5
  },
  {
    id: 7,
    visible: false,
    question:[],
    step: 6
  },
  {
    id: 8,
    visible: false,
    question:[],
    step: 7
  },
  {
    id: 9,
    visible: false,
    question:[],
    step: 8
  },
  {
    id: 10,
    visible: false,
    question:[],
    step: 9,
    parent: 3,
    answer: 1
  },
  {
    id: 11,
    visible: false,
    question:[],
  },
  {
    id: 12,
    visible: false,
    question:[],
    step: 11,
    parent:3,
    answer: 2
  },
  {
    id: 13,
    visible: false,
    question:[],
    step:12,
  },
  {
    id: 15,
    visible: false,
    question:[],
    step:17,
    parent: 3,
    answer: 2
  },
  {
    id: 16,
    visible: false,
    question:[],
    step:18,
    parent: 3,
    answer: 2
  },
  {
    id: 17,
    visible: false,
    question:[],
    step:20,
    parent: 3,
    answer: 2
  },
  {
    id: 18,
    visible: false,
    question:[],
    step:21,
  },
  {
    id: 19,
    visible: false,
    question:[],
    step:22
  }

]);

export default stepScheme
