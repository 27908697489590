<script setup>
import {computed, defineProps, inject, onMounted, ref} from 'vue'
import {email, helpers, minLength, required} from "@vuelidate/validators";
import {useVuelidate} from "@vuelidate/core";
import {useYandexMetrika} from "yandex-metrika-vue3";
import orderService from "@/services/order.service";

const yandexMetrika = useYandexMetrika()
const stateDone = inject('stateDone')

const props = defineProps({
  visibleModal: {
    type: Boolean,
  },
  doc: {
    type: String
  }
})
const emits = defineEmits(["close"])

const jobs = computed(() => {
    if(props.doc === 'ru'){
        return ['РУ', 'ВИРД', 'Консультация']
    }
    else if(props.doc === 'cert'){
      return [
        'Сертификат', 'Декларация', 'Консультация'
      ]
    }
    return []
})

const  product = computed(() => {

  if(props.doc === 'ru'){
    return 'РУ с лендинга'
  }
  else if(props.doc === 'cert'){
    return 'Сертификация с лендинга'
  }

  return 'none'
})

const order = ref({
  name: '',
  phone: '',
  email: '',
  content: '',
  product: '',
  jobs: []
})

const maskPhone = {
  mask: '+{7}(000) 000-0000',
  lazy: true
}

const validations = computed(() => {
  return {
    name: {
      required: helpers.withMessage('Обязательно к заполнению', required),
      minLength: helpers.withMessage('От трех знаков', minLength(3))
    },
    phone: {
      required: helpers.withMessage('Обязательно к заполнению', required),
    },
    email: {
      /*required:helpers.withMessage('Обязательно к заполнению', required),*/
      email: helpers.withMessage('Неправильный формат', email)
    },
    content: {
      required: helpers.withMessage('Обязательно к заполнению', required),
    }
  }
})

const v$ = useVuelidate(validations, order)

async function sendForm(){
  const isFormCorrect = await v$.value.$validate()

  if (!isFormCorrect) return
  const result = await orderService.post(order.value)
  if(result){

    closeModal()
    stateDone.value = true
    if(process.env.NODE_ENV !== 'development'){
      yandexMetrika.reachGoal('sendOrder')
    }

  }

}

function closeModal() {
  emits('close')
}

onMounted (async () => {
    order.value.product = product.value
} )

</script>

<template>
  <div :class="['modal', 'modal--contact-us', {'is-active': props.visibleModal}]">
    <div class="modal__wrapper">
      <div class="modal__overlay" data-close-modal></div>
      <div class="modal__content">
        <h3 class="page-title">Данные для расчета</h3>

        <form v-on:submit.prevent="sendForm" class="form modal__form">
          <div class="jobs">
            <h4>что Вам необходимо:</h4>
            <div class="jobs__list">
            <div class="jobs__list-item" v-for="(item, key) in jobs" :key="key">
              <input v-model="order.jobs" type="checkbox" :value="item"> {{item}}
            </div>
            </div>
          </div>
          <div class="form__fields-wrapper">
            <!-- если поле с ошибкой, вешать на label класс error-field и показывать спан с текстом ошибки error-field__text -->
            <label :class="['form__field', {'error-field': order.name.length === 0 }]">
              <input :class="{'filled': order.name.length > 0}" v-model="order.name" type="text" name="name">
              <span class="form__field-placeholder">Имя</span>
              <span v-if="v$.name.$dirty && v$.name.required.$invalid" class="error-field__text">
                 {{ v$.name.required.$message }}
              </span>
            </label>
            <label :class="['form__field', {'error-field': order.phone.length === 0 }]">
              <input :class="{'filled': order.phone.length > 0}"
                     v-model="order.phone"
                     v-imask="maskPhone"
                     type="tel"
                     name="tel">
              <span class="form__field-placeholder">Номер телефона</span>
              <span v-if="v$.phone.$dirty && v$.phone.required.$invalid" class="error-field__text">
                 {{ v$.phone.required.$message }}
              </span>
            </label>
          </div>
          <label :class="['form__field', {'error-field': order.content.length === 0 }]">
            <textarea :class="{'filled': order.content.length > 0}" v-model="order.content" name="description"
                      class="form__field"></textarea>
            <span class="form__field-placeholder">Добавить описание</span>
            <span v-if="v$.content.$dirty && v$.content.required.$invalid" class="error-field__text">
                 {{ v$.name.required.$message }}
              </span>
          </label>
          <div class="form__buttons">
            <button class="button button--dark-blue" data-close-modal type="submit">Отправить</button>
            <p>Нажимая эту кнопку Вы соглашаетесь с <a href="#">условиями обработки информации</a></p>
          </div>
        </form>
        <button @click="closeModal" class="modal__close-btn btn-reset" type="button" aria-label="Закрыть попап"
                data-close-modal>
          <svg width="27" height="27">
            <use href="#icon-cross"></use>
          </svg>
        </button>
      </div>

    </div>
  </div>
</template>

<style scoped lang="scss">
  .jobs{
    margin: 15px 0;
      h4{
        margin: 7px 0;
      }
  }
  .jobs__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
  }
  .jobs__list-item {
      margin-right: 10px;
      font-size: 20px;
    color: #638aa8;
  }
</style>
