import axios from "axios";

const authService = {
    async login(form) {
        try {
          const {status, data} = await axios(process.env.VUE_APP_URL_API + 'user/login', {
            method: 'POST',
            data: form,
            headers: {
              Origin: 'https://evelans.ru',
              'User-Agent': ' curl/7.51.0',
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Methods': 'GET,PATCH,OPTIONS',
              'Access-Control-Allow-Headers': 'Origin, Accept, Content-Type, Access-Control-Allow-Origin',
              'Content-Type': 'application/json; charset=UTF-8'
            }
          })
          if(status === 200){
              return data
          }
        } catch (e){
          return e
      }
    },

    async save(data)
    {
        localStorage.setItem('token', data.access_token)
    }
}

export default authService
