<script setup>

const props = defineProps({
  icon: {
    type: String,
  },
  size: Number,
  fill: String,
  background: {
    type: Object
  }
})
</script>

<template>
    <svg :width="props.size" :height="props.size" :fill="props.fill">
      <circle :cx="props.background.size" :cy="props.background.size" :r="props.background.size"
              :fill="props.background.color" v-if="props.background"/>
      <use :xlink:href="'#'+ props.icon" ></use>
    </svg>
</template>

<style scoped lang="scss">

</style>
