<script setup>
import {computed, defineProps, ref} from 'vue'

const props = defineProps({
  outLinks: Array
})
//const  { outLinks } = toRefs(props)

const links = ref([
  {
    name: 'Главная',
    href: '/'
  }
])

const viewBreadcrumbs = computed(() => {
 if (props.outLinks.length >= 1) {
    return  links.value.concat(props.outLinks)
  }
  return links.value
})


</script>

<template>
  <div class="container breadcrumbs">
    <ul>
      <li v-for="(item, key) in viewBreadcrumbs" :key="key">
        <RouterLink :to="item.href">{{ item.name }}</RouterLink>
      </li>
    </ul>
  </div>
</template>

<style scoped lang="scss">

</style>
