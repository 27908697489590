const mapScheme = [
  {
    id: 1,
    name: 'Разработка проектов документов регистрационного досье',
    amount: 0,
    day: 'work'
  },
  {
    id:2,
    name: 'Уведомление о ввозе образцов в РФ',
    amount: 5,
    day: 'work'
  },
  {
    id: 3,
    name: 'Технические испытания, токсикологические испытания, клинические испытания',
    amount: 30,
    day: 'work'
  },
  {
    id: 4,
    name: 'Технические испытания, токсикологические испытания, инспектирование производства для классов риска 2а (стерильные), 2б и 3.',
    amount: 30,
    day: 'work'
  },
  {
    id: 5,
    name: 'Формирование регистрационного досье',
    amount: 10,
    day: 'work'
  },
  {
    id: 6,
    name: 'Подача регистрационного досье в Росздравнадзор',
    amount: 0,
    day: 'work'
  },
  {
    id: 7,
    name: 'Рассмотрение документов регистрационного досье',
    amount: 5,
    day: 'work'
  },
  {
    id: 8,
    name: 'Устранение формальных замечаний(при их наличии)',
    amount: 30,
    day: 'calendar'
  },
  {
    id: 9,
    name: 'Экспертиза заявления и экспертиза качества, эффективности и безопасности МИ',
    amount: 10,
    day: 'work'
  },
  {
    id: 10,
    name: 'Возврат заявления и документов регистрационного досье(в случае неустранения замечаний)',
    amount: 0,
    day: 'work'
  },
  {
    id: 11,
    name: '1 этап экспертизы качества, эффективности и безопасности медицинского изделия',
    amount: 20,
    day: 'work'
  },
  {
    id: 12,
    name: 'Устранение замечаний(при их наличии)',
    amount: 50,
    day: 'work'
  },
  {
    id: 13,
    name: 'Принятие решения о государственной регистрации медицинского изделия',
    amount: 10,
    day: 'work'
  },
  {
    id: 14,
    name: 'Выдача разрешения на проведение клинических испытаний',
    amount: 5,
    day: 'work'
  },
  {
    id: 15,
    name: 'Отказ в государственной регистрации',
    amount: 0,
    day: 'work'
  },
  {
    id: 16,
    name: 'Выдача регистрационного удостоверения',
    amount: 0,
    day: 'work'
  },
  {
    id: 17,
    name: 'Проведение клинических испытаний',
    amount: 30,
    day: 'work'
  },
  {
    id: 18,
    name: 'Подача результатов клинических испытаний в Росздравнадзор',
    amount: 10,
    day: 'work'
  },
  {
    id: 19,
    name: 'Возврат документов регистрационного досье(в случае неустранения замечаний)',
    amount: 0,
    day: 'work'
  },
  {
    id: 20,
    name: '2 этап экспертизы качества, эффективности и безопасности медицинского изделия',
    amount: 10,
    day: 'work'
  },
  {
    id: 21,
    name: 'Принятие решения о государственной регистрации медицинского изделия',
    amount: 10,
    day: 'work'
  },
  {
    id: 22,
    name: 'Выдача регистрационного удостоверения',
    amount: 0,
    day: 'work'
  },
]

export default mapScheme
