<script setup>
import {ref, defineEmits} from 'vue'
import {useYandexMetrika} from "yandex-metrika-vue3";

const yandexMetrika = useYandexMetrika()

const props = defineProps({
  title: {
    type: String,
    default: 'Оформление <span class="subtitle">сертификатов соответствия</span><span class="subtitle2">на любую продукцию</span>'
  }
})

//const introTitle = 'Оформление <span class="subtitle">сертификатов соответствия</span><span>на любую продукцию</span>'
const introDop = 'Минимум документов при оформлении и выгодные условия'

const emits = defineEmits(['open'])

function openModal(){
  yandexMetrika.reachGoal('openForm')
    emits('open')
}


const intro = ref(false)
window.addEventListener('scroll', function () {
  if (window.pageYOffset > 10) {
      intro.value = true
  } else {
     intro.value = false
  }
})

//const nameButton = 'Получить расчет стоимости'
const nameButton = 'Рассчитайте стоимость сертификации'

</script>

<template>
  <section :class="['intro', {'scrolled': intro}]">
    <div class="container intro__container">
      <p class="intro-text">{{ $t('title') }}</p>
      <h2 class="intro__title" v-html="props.title"></h2>
      <h4 class="intro__title-due">{{ introDop }}</h4>
      <button @click="openModal" class="button button--red button--border" data-modal-open="contact-us">{{ nameButton }}</button>
    </div>
  </section>
</template>

<style scoped lang="scss">

</style>
