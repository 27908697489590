<script setup>

import SvgBlock from "@/layouts/SvgBlock.vue";
import Footer from "@/layouts/part/Footer.vue";
import ModalForm from "@/layouts/ModalForm.vue";
import Header from "@/layouts/part/Header.vue";
import {onMounted, ref} from "vue";
import menuService from "@/services/menu.service";
import {useI18n} from "vue-i18n";
import bitrix from "@/helpers/bitrix";

const { t, locale } = useI18n({useScope: "global"})
const navMenu = ref([])

const loadMenu = async () => await menuService.basic(locale.value)

onMounted(async() => {
  navMenu.value = await loadMenu()
})
</script>

<template>
  <SvgBlock/>
  <div class="wrapper">
    <Header  :navMenu="navMenu" />
    <main class="main">
      <router-view/>
    </main>
    <Footer :navMenu="navMenu" />
    <ModalForm :dataModal="dataModal" />
  </div>
</template>

<style scoped lang="scss">

</style>
