<script setup>
import {onMounted, ref, inject, defineProps, watch} from 'vue'
import menuService from "@/services/menu.service";
import { useYandexMetrika } from 'yandex-metrika-vue3'
import {useI18n} from "vue-i18n";
import utmService from "@/services/utm.service";
import {useRoute, useRouter} from "vue-router";
import IconSvg from "@/components/ui/IconSvg.vue";

const { t, locale } = useI18n({useScope: "global"})

const router = useRouter()
const route = useRoute()

const utmData = utmService.read()

onMounted(async () => {
    await router.isReady()
    saveUtm()
})

const yandexMetrika = useYandexMetrika()

const mobMenu = ref(false)
const mobSubMenu = ref(false)
const subMenu = ref(0)

const props = defineProps({
  navMenu: Array
})
const modal = inject('stateModal')




function activeSubmenu(id) {
  subMenu.value = id
}
function closeSubMenu() {
  subMenu.value = 0
  mobMenu.value = false
}
function isActive(id) {
  if (subMenu.value === id) {
    return true;
  }
  return false;
}
function openMenu() {
  mobMenu.value = !mobMenu.value
}
function toggleSubMenu(item) {
  subMenu.value = item.id
  if (item.items.length === 0) {
    mobMenu.value = false
  }
  mobSubMenu.value = !mobSubMenu.value
}
function openFeedback() {
  yandexMetrika.reachGoal('openForm')
  modal.value = {
    state: true,
    name: 'Обратная связь'
  }
}

function sendTG(){
  yandexMetrika.reachGoal('openTG')
}
function getClass(slug) {
  if (slug === 'certificate') {
    return 'header__submenu-cert'
  } else if (slug === 'registry') {
    return 'header__submenu-reg'
  } else {
    return 'header__submenu-about'
  }
}
function getUrl(item) {
  if (item.items.length > 0) {
    return '#'
  }
  return '/' + item.alias
}

function switchLang() {
  locale.value = locale.value === 'ru'? locale.value = 'ch' : locale.value = 'ru'
  localStorage.setItem('lang', locale.value)
}
function visibleBlock(){
    if(process.env.NODE_ENV === 'production'){
        return false
    }
    return true
}

watch(locale, (newValue, oldValue) => {
    location.reload()
})

function saveUtm(){
  if(utmData === null || Object.keys(utmData).length === 0 ) {
    utmService.save(route.query)
  }
}


</script>

<template>
  <suspense>
    <template #default>
  <header :class="['header', {'nav-opened':mobMenu}]">
    <div class="container header__container">
      <a href="/" class="header__logo">
        <img src="/img/header-logo.png" width="140" height="15" alt="Логотип компании"
             srcset="/img/header-logo.png 1x, /img/header-logo@2x.png 2x">
      </a>
      <button class="header__burger js_open-nav" aria-label="Открыть меню" @click="openMenu">
        <IconSvg size="30" icon="icon-burger" fill="black"/>
      </button>
      <nav class="header__nav">
        <ul class="header__menu">
          <li v-for="item in props.navMenu" :key="item.id" class="header__menu-item"
              @mouseover="mobMenu ? null : activeSubmenu(item.id)"
              @mouseout="mobMenu ? null : closeSubMenu()"
          >
            <RouterLink :class="['header__menu-link', {'is-active':isActive(item.id)}]"
                        :to="getUrl(item)"
                        @click="toggleSubMenu(item)">
              {{ item.name }}
              <svg width="20" height="20" v-if="(item.items.length > 0)">
                <use xlink:href="#icon-plus"></use>
              </svg>
            </RouterLink>
            <div :class="['header__submenu', {'is-active': isActive(item.id)}]" v-if="(item.items.length > 0)">
              <div class="container header__submenu-container" v-if="item.alias === 'section'">
                <div v-for="submenu in item.items" :key="submenu.id" :class="getClass(submenu.alias)">
                  <RouterLink
                    :to="'/'+ item.alias + '/' +submenu.alias"
                    class="header__submenu-title"
                    @click="closeSubMenu">
                    {{ submenu.name }}
                  </RouterLink>
                  <ul class="header__submenu-list" v-if="submenu.items.length > 0">
                    <li>
                      <ul>
                        <li v-for="inSubmenu in submenu.items" :key="inSubmenu.id">
                          <RouterLink
                            :to="'/'+ item.alias + '/' +submenu.alias + '/' + inSubmenu.alias"
                            @click="closeSubMenu"
                          >{{ inSubmenu.name }}
                          </RouterLink>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="container header__submenu-container" v-else>
                <div class="header__submenu-about">
                  <a class="header__submenu-title">{{ item.name }}</a>
                  <ul>
                    <RouterLink v-for="submenu in item.items" :key="submenu.id"
                                style="border: 1px black solid"
                                custom
                                :to="'/'+ item.alias + '/' +submenu.alias "
                                v-slot="{ href, navigate }"
                    >
                      <li @click="[navigate, closeSubMenu]">
                        <a :href="href">{{ submenu.name }}
                        </a>
                      </li>
                    </RouterLink>
                  </ul>
                </div>
              </div>
            </div>
          </li>
          <li class="header__menu-item">
            <a @mouseover="subMenu=0"
               @click="openFeedback"
               class="header__menu-link"
               data-modal-open="contact-us">Обратная связь</a></li>
        </ul>
        <ul class="header__contacts">
          <li class="header__phone"><a :href="'tel:' + basicPhone">{{ basicPhone }}</a></li>
          <li class="header__socials">
            <a :href="tmBot" @click="sendTG">
              <IconSvg size="35" icon="icon-telegram" fill="#6388A8"/>
            </a>
          </li>
          <li class="header__phone" v-if="visibleBlock()">
            <span @click="switchLang" style="margin: 0 5px; cursor: pointer;min-width: 15px">
              {{ $t('switch') }}
            </span>
          </li>
        </ul>
      </nav>
    </div>
  </header>
    </template>
    <template #fallback>
      <div>Загрузка информации, чуть-чуть подождите</div>
    </template>
  </suspense>
</template>

<style scoped lang="scss">
  .header__menu-item{
      border-left: 1px #d5d4d4 solid;
      margin: 0;
      padding: 0 25px;
  }
  .header__submenu{
      padding-top: 30px;
  }
</style>
