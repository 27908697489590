<script setup>

import { inject } from "vue";

const modal = inject('stateModal')

function openFeedback(){
  modal.value = true
}


</script>

<template>
  <footer class="footer">
    <div class="container">
      <div class="footer__nav">
          <img src="/img/footer-logo.png" width="122" height="150" alt="Логотип компании" srcset="/img/footer-logo.png 1x, /img/footer-logo@2x.png 2x">
        <ul class="footer__menu">
          <li class="footer__menu-item">
            <a @click="openFeedback" data-modal-open="contact-us">Обратная связь</a>
          </li>
        </ul>
        <div class="footer__contacts">
          <div class="footer__contacts-phones">
            <a :href="'tel:' + basicPhone">{{ basicPhone }}</a>
          </div>
          <p class="footer__contacts-address">
            <a :href="'mailto:' + basicEmail" >{{ basicEmail }}</a>
          </p>
          <ul class="footer__socials">
            <li>
              <a href="https://t.me/evelans_group">
                <svg width="20" height="20" fill="white">
                  <use xlink:href="#icon-telegram-footer"></use>
                </svg>
              </a>
            </li>
         </ul>
        </div>
      </div>
      <div class="footer__bottom">
        <p>Все права защищены</p>
      </div>
    </div>
  </footer>
</template>

<style scoped lang="scss">
.footer__submenu-list{
  column-count: unset;
}
.footer__submenu-list li ul{
  //flex-direction: row;
}
.footer__submenu-list li{
  break-inside: unset;
}
</style>
