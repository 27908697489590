<script setup>
import {onMounted, ref, watch} from 'vue'
import ralService from "@/services/ral.service";
import LaboratoryBlock from "@/components/LaboratoryBlock.vue";
import Swiper from "swiper"
import {Navigation} from "swiper/modules";
import { useRoute } from "vue-router";

const outLinks = [
  {
    name: 'Наши лаборатории',
    href: 'ral'
  }
]

const nameSection = 'Лаборатории партнеры'
const oneRal = ref({
  name: '',
  content: '',
  testObject: [],
  addInfo: []
})
const showRal = ref(false)
const route = useRoute()

const loadRal = async () => ralService.index()

const rals = ref([])

onMounted(async () => {
  rals.value = await loadRal()
  goSwiper()
  if(route.query.id !== undefined){
      openRal(Number(route.query.id))
  }

})

function goSwiper(){
  const partnerSliderContainer = document.querySelector('.partner__slider-container');
  if (partnerSliderContainer) {
    const prevBtn = partnerSliderContainer.querySelector('.slider-btn--prev');
    const nextBtn = partnerSliderContainer.querySelector('.slider-btn--next');
    new Swiper(partnerSliderContainer, {
      slidesPerView: 1,
      spaceBetween: 0,
      direction: 'horizontal',
      modules: [Navigation],
      navigation: {
        nextEl: nextBtn,
        prevEl: prevBtn,
      },

      breakpoints: {
        500: {
          slidesPerView: 2,
          spaceBetween: 20,
        },

        768: {
          slidesPerView: 3,
          spaceBetween: 20,
        },

        1024: {
          slidesPerView: 4,
          spaceBetween: 20,
        }
      },
    });
  }
}
function fullPath(logo){
  return process.env.VUE_APP_URL_SITE + 'images/ral/'+ logo
}

function openRal(id){
    oneRal.value = rals.value.find(item => item.id === id)
    showRal.value = true
}

function reSize(content){
  return content.slice(0, 100) + '...'
}

</script>

<template>
  <Breadcrumbs :out-links="outLinks"/>
  <LaboratoryBlock :ral="oneRal" :showRal="showRal"/>
  <section class="container partner">
    <h2 class="section-title">{{ nameSection }}</h2>
    <div class="partner__slider-container swiper-container">
      <div class="slider-controls">
        <a class="slider-btn slider-btn--prev swiper-button-prev">
          <svg width="26" height="26">
            <use xlink:href="#icon-arrow"></use>
          </svg>
        </a>
        <a class="slider-btn slider-btn--next swiper-button-next">
          <svg width="26" height="26">
            <use xlink:href="#icon-arrow"></use>
          </svg>
        </a>
      </div>
      <ul class="partner__slider swiper-wrapper">
        <li class="partner__item swiper-slide" v-for="item in rals" :key="item.id">
          <div @click="openRal(item.id)">
            <img :src="fullPath(item.logo)" width="124" height="34" alt="Логотип">
          </div>
          <p>{{ reSize(item.content) }}</p>
        </li>
      </ul>
    </div>
  </section>
</template>

<style scoped lang="scss">

</style>
