<script setup>

import IntroLandingCertificate from "@/components/IntroLandingCertificate.vue";
import BenefitsBlock from "@/components/BenefitsBlock.vue";
import {inject, onMounted, ref} from "vue";
import sectionService from "@/services/section.service";
import QuoteBlock from "@/components/block/QuoteBlock.vue";
import ButtonOrder from "@/components/ui/ButtonOrder.vue";

const document = ref(false)
const loadDocument = async () => await sectionService.oneDocument("certificate_trts")

onMounted(async () => {
  document.value = await loadDocument()
  tab.value = document.value.textBook.find((item, key) => key === 0)
})

const tab = ref({})

function changeTab(id) {
  tab.value = document.value.textBook.find(item => item.id === id)
}

const emits = defineEmits(['open', 'doc'])
function openModal(){
 emits('open')
}
emits('doc', 'cert')

const modal = inject('stateModal')

function openModalOrder() {
  modal.value = {
    state: true,
    name: 'Консультация с лендинга сертификации'
  }
}

</script>

<template>
  <section class="container registryLanding">
    <IntroLandingCertificate @open="openModal" :title="'Сертификация товаров <span class=\'subtitle\'>для продажи на маркетплейсах</span>\n'+
'<span class=\'subtitle2\'>Wildberries и Ozon</span>'"/>
    <div class="bookmark">
      <div :class="['bookmark--item', {active: item.id === tab.id}]" v-for="item in document.textBook" :key="item.id">
        <div @click="changeTab(item.id)" class="bookmark_nav button button--square">
          {{ item.bookmark }}
        </div>
      </div>
    </div>
    <div v-show="tab.content" class="content" v-html="tab.content">
    </div>
    <BenefitsBlock :title="'Почему нам доверяют:'" :direction="'certificate'"/>
    <QuoteBlock :item="{body:' Аттестат государственной аккредитации RA.RU.11АЖ06\n'+
'      ООО Гринлайн'}"/>
    <div class="center-block">
      <ButtonOrder @open="openModalOrder" :name="'Получить бесплатную консультацию по сертификации'" :classColor="'button--red'"/>
    </div>

  </section>

</template>

<style scoped lang="scss">
  .center-block{
    text-align: center;
  }
</style>
