import axios from "axios";
import UtmService from "@/services/utm.service";

const orderService = {
    async post(order){
      const utm = UtmService.read()
      if(utm || Object.keys(utm).length > 0){
        order.utm =  utm
      }

      const {status, data} =  await axios(process.env.VUE_APP_URL_API + 'order/send', {
        method: 'POST',
        data: order,
        headers: {
          Origin: 'https://evelans.ru',
          'User-Agent': ' curl/7.51.0',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET,PATCH,OPTIONS',
          'Access-Control-Allow-Headers': 'Origin, Accept, Content-Type, Access-Control-Allow-Origin',
          'Content-Type': 'application/json; charset=UTF-8'
        }
      })

      if(status === 200){
          return data
      }
    }
}

export default orderService
