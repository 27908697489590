<script setup>
import {lightScheme, step} from "@/helpers/lightScheme";
import allScheme from "@/helpers/allScheme";


function nameBlock(step){
    let name = lightScheme.find((item) => item.id === step)
    return name.name
}

function getDayStep(step){
  let itemStep = lightScheme.find(item => item.id === Number(step))
  if (itemStep !== undefined) {
    return itemStep.amount
  }
  return 0
}
function getJobDay(step){
  let itemStep = lightScheme.find(item => item.id === Number(step))
  if (itemStep !== undefined && itemStep.day === 'work') {
    return false
  }
  return true
}

</script>



<template>
  <section>

      <h1>Порядок упрощенной регистрации</h1>
       <div style="display: flex" v-for="item in step" :key="item.id">
          <div :class="['block-scheme', {active: item.visible}]">
              {{ nameBlock(item.step) }}
            <span v-if="getDayStep(item.step) !== 0"
                  :class="['label', {green:getJobDay(item.step)}]"
            >
        {{ getDayStep(item.step) }}
      </span>
          </div>
       </div>
    <div class="comment">
      <div><label class="work">5</label> - рабочие дни</div>
      <div><label class="calendar">10</label> - календарные дни</div>
    </div>
  </section>
</template>

<style scoped lang="scss">

</style>
