import { createApp } from 'vue'
import App from './App.vue'
import { createPinia } from 'pinia'
import router from './router'
import config from "@/helpers/config"
import { IMaskDirective } from 'vue-imask'
import { initYandexMetrika } from 'yandex-metrika-vue3';
import { createI18n, useI18n } from "vue-i18n";
import './styles/main.scss'

import { languages } from "@/i18n";
import { defaultLocale } from "@/i18n";

const messages = Object.assign(languages)
const pinia = createPinia()

const localStorageLang = localStorage.getItem('lang')
const i18n = createI18n({
  locale: localStorageLang || defaultLocale,
  legacy: false,
  fallbackLocale: 'ru',
  messages
})



const vm = createApp(App, {
  setup(){
    const {t} = useI18n()
    return {t}
  }
})
  .directive('imask', IMaskDirective)
  .use(router)
  .mixin(config)
  .use(pinia)
  .use(i18n)
  .use(initYandexMetrika, {
    id: 96840977,
    router: router,
    env: process.env.NODE_ENV,
    debug:true,
    options: {clickmap:true, trackLinks:true, accurateTrackBounce:true, webvisor: true,}
  })
  .mount('#app')

