<script setup>

</script>

<template>
  <div class="container">
    <div class="row">
      <div>
        <svg width="60" height="60">
          <use xlink:href="#icon-skype"></use>
        </svg>
      </div>
      <div style="background-color: white">
        <svg width="60" height="60">
          <use xlink:href="#advantage-1"></use>
        </svg>
      </div>
      <div style="background-color: white">
        <svg width="60" height="60">
          <use xlink:href="#advantage-2"></use>
        </svg>
      </div>
      <div style="background-color: white">
        <svg width="60" height="60">
          <use xlink:href="#advantage-3"></use>
        </svg>
      </div>
      <div style="background-color: white">
        <svg width="60" height="60">
          <use xlink:href="#advantage-4"></use>
        </svg>
      </div>
      <div style="background-color: white">
        <svg width="60" height="60">
          <use xlink:href="#advantage-5"></use>
        </svg>
      </div>
      <div style="background-color: white">
        <svg width="60" height="60">
          <use xlink:href="#advantage-6"></use>
        </svg>
      </div>
      <div style="background-color: white">
        <svg width="60" height="60">
          <use xlink:href="#advantage-7"></use>
        </svg>
      </div>
      <div style="background-color: white">
        <svg width="60" height="60">
          <use xlink:href="#advantage-8"></use>
        </svg>
      </div>
      <div style="background-color: white">
        <svg width="60" height="60">
          <use xlink:href="#icon-about-1"></use>
        </svg>
      </div>
      <div style="background-color: white">
        <svg width="60" height="60">
          <use xlink:href="#icon-about-2"></use>
        </svg>
      </div>
      <div style="background-color: white">
        <svg width="60" height="60">
          <use xlink:href="#icon-about-3"></use>
        </svg>
      </div>
      <div style="background-color: white">
        <svg width="60" height="60">
          <use xlink:href="#icon-arrow"></use>
        </svg>
      </div>
      <div style="background-color: white">
        <svg width="60" height="60">
          <use xlink:href="#icon-burger"></use>
        </svg>
      </div>
      <div style="background-color: white">
        <svg width="60" height="60">
          <use xlink:href="#icon-cross"></use>
        </svg>
      </div>
      <div style="background-color: white">
        <svg width="60" height="60">
          <use xlink:href="#icon-location"></use>
        </svg>
      </div>
      <div style="background-color: white">
        <svg width="60" height="60">
          <use xlink:href="#icon-mail"></use>
        </svg>
      </div>
      <div style="background-color: white">
        <svg width="60" height="60">
          <use xlink:href="#icon-object-1"></use>
        </svg>
      </div>
      <div style="background-color: white">
        <svg width="60" height="60">
          <use xlink:href="#icon-object-2"></use>
        </svg>
      </div>
      <div style="background-color: white">
        <svg width="60" height="60">
          <use xlink:href="#icon-object-3"></use>
        </svg>
      </div>
      <div style="background-color: white">
        <svg width="60" height="60">
          <use xlink:href="#icon-object-4"></use>
        </svg>
      </div>
      <div style="background-color: white">
        <svg width="60" height="60">
          <use xlink:href="#icon-object-5"></use>
        </svg>
      </div>
      <div style="background-color: white">
        <svg width="60" height="60">
          <use xlink:href="#icon-object-6"></use>
        </svg>
      </div>
      <div style="background-color: white">
        <svg width="60" height="60">
          <use xlink:href="#icon-object-7"></use>
        </svg>
      </div>
      <div style="background-color: white">
        <svg width="60" height="60">
          <use xlink:href="#icon-object-8"></use>
        </svg>
      </div>
      <div style="background-color: white">
        <svg width="60" height="60">
          <use xlink:href="#icon-object-9"></use>
        </svg>
      </div>
      <div style="background-color: white">
        <svg width="60" height="60">
          <use xlink:href="#icon-object-10"></use>
        </svg>
      </div>
      <div style="background-color: white">
        <svg width="60" height="60">
          <use xlink:href="#icon-object-11"></use>
        </svg>
      </div>
      <div style="background-color: white">
        <svg width="60" height="60">
          <use xlink:href="#icon-object-12"></use>
        </svg>
      </div>
      <div style="background-color: white">
        <svg width="60" height="60">
          <use xlink:href="#icon-object-13"></use>
        </svg>
      </div>
      <div style="background-color: white">
        <svg width="60" height="60">
          <use xlink:href="#icon-object-14"></use>
        </svg>
      </div>
      <div style="background-color: white">
        <svg width="60" height="60">
          <use xlink:href="#icon-object-15"></use>
        </svg>
      </div>
      <div style="background-color: white">
        <svg width="60" height="60">
          <use xlink:href="#icon-object-16"></use>
        </svg>
      </div>
      <div style="background-color: white">
        <svg width="60" height="60">
          <use xlink:href="#icon-phone"></use>
        </svg>
      </div>
      <div style="background-color: white">
        <svg width="60" height="60">
          <use xlink:href="#icon-plus"></use>
        </svg>
      </div>
      <div>
        <svg width="60" height="60">
          <use xlink:href="#icon-telegram-footer"></use>
        </svg>
      </div>
      <div style="background-color: white">
        <svg width="60" height="60">
          <use xlink:href="#icon-telegram"></use>
        </svg>
      </div>
      <div>
        <svg width="60" height="60">
          <use xlink:href="#icon-whatsapp"></use>
        </svg>
      </div>
      <div style="border-radius: 50%; border:1px solid gray">
        <svg width="60" height="60" >
          <use xlink:href="#icon-object-17"></use>
        </svg>
      </div>
      <div style="background-color: #4f718f; border-radius: 50%;">
        <svg width="60" height="60" fill="white">
          <use xlink:href="#icon-object-18"></use>
        </svg>
      </div>
      <div style="background-color: #4f718f; border-radius: 50%;">
        <svg width="60" height="60" fill="white">
          <use xlink:href="#icon-object-19"></use>
        </svg>
      </div>
      <div style="background-color: #4f718f; border-radius: 50%;">
        <svg width="60" height="60" fill="white">
          <use xlink:href="#icon-object-20"></use>
        </svg>
      </div>
      <div style="background-color: #4f718f; border-radius: 50%;">
        <svg width="60" height="60" fill="white">
          <use xlink:href="#icon-object-21"></use>
        </svg>
      </div>
      <div style="background-color: #4f718f; border-radius: 50%;">
        <svg width="60" height="60" fill="white">
          <use xlink:href="#icon-object-22"></use>
        </svg>
      </div>
      <div>
        <svg width="60" height="60">
          <use xlink:href="#broker"></use>/
        </svg>
      </div>
      <div>
        <svg width="60" height="60">
          <use xlink:href="#cube"></use>/
        </svg>
      </div>
      <div>
        <svg width="60" height="60">
          <use xlink:href="#auto"></use>/
        </svg>
      </div>
      <div>
        <svg width="60" height="60">
          <use xlink:href="#avia"></use>/
        </svg>
      </div>
      <div>
        <svg width="60" height="60">
          <use xlink:href="#insures"></use>/
        </svg>
      </div>
      <div>
        <svg width="60" height="60">
          <use xlink:href="#jeldor"></use>/
        </svg>
      </div>
      <div>
        <svg width="60" height="60">
          <use xlink:href="#container"></use>/
        </svg>
      </div>
      <div>
        <svg width="60" height="60">
          <use xlink:href="#multi"></use>/
        </svg>
      </div>
      <div>
        <svg width="60" height="60">
          <use xlink:href="#consult"></use>/
        </svg>
      </div>
      <div>
        <svg width="60" height="60">
          <use xlink:href="#ok"></use>/
        </svg>
      </div>
      <div>
        <svg width="60" height="60">
          <use xlink:href="#handle"></use>/
        </svg>
      </div>
      <div>
        <svg width="60" height="60">
          <use xlink:href="#oficial"></use>/
        </svg>
      </div>
      <div>
        <svg width="60" height="60">
          <use xlink:href="#globus"></use>/
        </svg>
      </div>
      <div>
        <svg width="60" height="60">
          <use xlink:href="#papka"></use>/
        </svg>
      </div>

    </div>

  </div>
</template>

<style scoped lang="scss">
  .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .row * {
    margin: 10px;
  }
</style>
