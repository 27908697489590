<script setup>

import Breadcrumbs from "@/components/Breadcrumbs.vue";
import {ref} from "vue";

const outLinks = ref([
  {
    name: 'Транспортировка',
    href: '/about/cargo'
  }
])

const advert = [
  {
    id: 1,
    text: 'Комплексное решение логистических задач',
    icon: '#papka'
  },
  {
    id: 2,
    text: 'Доставка и таможенное оформление в одних руках',
    icon: '#handle'
  },
  {
    id: 3,
    text: 'Официальный и лицензированный таможенный представитель',
    icon: '#oficial'
  },
  {
    id: 4,
    text: 'Перевозка любых категорий грузов',
    icon: '#cube'
  },
  {
    id: 5,
    text: 'Широкая география перевозок, агентская сеть по всему миру',
    icon: '#globus'
  },
  {
    id: 6,
    text: 'Оптимальные и прозрачные условия',
    icon: '#ok'
  }
]

const country = [
  'Италия',
  'Индия',
  'Китай',
  'США',
  'Турция',
  'Вьетнам',
  'Куба',
  'Великобритания',
  'Германия',
  'Голландия',
  'Испания',
  'Польша',
  'Франция',
  'Чехия',
  'Финляндия',
  'Швеция',
  'ОАЭ',
  'Иран'
];

const category = [
  {
    id: 1,
    name: 'Таможенное оформление',
    icon: '#advantage-8'
  },
  {
    id: 2,
    name: 'Страхование грузов',
    icon: '#insures'
  },
  {
    id: 3,
    name: 'Автомобильные перевозки',
    icon: '#auto'
  },
  {
    id: 4,
    name: 'Авиаперевозки',
    icon: '#avia'
  },
  {
    id: 5,
    name: 'Железнодорожные перевозки',
    icon: '#jeldor'
  },
  {
    id: 6,
    name: 'Морские перевозки',
    icon: '#advantage-6'
  },
  {
    id: 7,
    name:  'Контейнерные перевозки',
    icon: '#container'
  },
  {
    id: 8,
    name: 'Мультимодальные перевозки',
    icon: '#multi'
  },
  {
    id: 9,
    name: 'ВЭД специалист (консультации)',
    icon: '#consult'
  },
  {
    id: 10,
    name: 'Сборные грузы',
    icon: '#cube'
  },
  {
    id: 11,
    name: 'Таможенный брокер',
    icon: '#broker'
  }
]

</script>

<template>
  <section class="container">
    <Breadcrumbs :out-links="outLinks"/>
    <div>
      <h2 class="section-title">Полный комплекс услуг по международным и внутренним грузоперевозкам.</h2>
      <ul class="advantages__list">
        <li v-for="item in category" :key="item.id" class="advantages__item_sm advantages__item">
          <svg width="30" height="30">
            <use :xlink:href="item.icon"></use>
          </svg>
          <div>
            <h3>{{ item.name }}</h3>
          </div>
        </li>
      </ul>
    </div>
  </section>
  <section class="container">
    <div class="alert-map">
      <div class="item_map">
        <img src="/img/sprite/maps_country.svg" alt="">
      </div>
      <div class="text_map">
        <h2 class="section-title">Преимущества:</h2>
        <ul class="advantages__list">
          <li v-for="item in advert" :key="item.id" class="advantages__item opacity">
            <svg width="40" height="40" fill="#6388A8">
              <use :xlink:href="item.icon"></use>
            </svg>
            <div class="">
              <h3>{{ item.text }}</h3>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </section>
  <section class="container advantages advantages--alt">
      <div class="country">
        <h3>География перевозок</h3>
        <ul>
          <li v-for="(item, key) in country" :key="key">
            {{ item }}
          </li>
        </ul>
      </div>
  </section>

</template>

<style scoped lang="scss">
  .advantages__list .advantages__item{
      margin-bottom: 20px;
  }
  .advantages__list .opacity svg{
    opacity: 1;
  }
  .advantages__item_sm {
    width: 23%;
    box-shadow: 3px 10px 10px 0 rgba(0,0,0,.15),-5px -4px 35px 0 #fff;
    margin-right: 2%;
    @media (max-width: 1100px) {
      width: 48%;
    }
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  .alert-map {
    position: relative;
  }
  .item_map {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0.3;
  }
  .text_map {
    box-shadow: 3px 10px 10px 0 rgba(0,0,0,.15),-5px -4px 35px 0 #fff;
    padding: 40px 30px;
    //border: 1px solid black;
    min-height: 400px;
    .section-title {
        text-align: left;
    }
    span {
      margin: 30px;
      font-size: 30px;
      color: black;
    }
  }
  .country{
      ul{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        li{
          margin: 20px 5%;
          list-style: none;
          font-size: 20px;
        }
      }
  }

</style>
