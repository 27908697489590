<script setup>

import Breadcrumbs from "@/components/Breadcrumbs";
import {useRoute} from "vue-router";
import {inject, onMounted, ref, watch} from "vue";
import sectionService from "@/services/section.service";
import ModalWindow from "@/layouts/ModalWindow.vue";
import ShemeBlock from "@/components/scheme/SchemeBlock.vue";

const route = useRoute()
const outLinks = ref([])
const openScheme = ref(false)

const section = ref(false)
const loadSection = async () => await sectionService.oneSection(route.params.alias)


async function loadOutLinks() {
  return [
    {
      name: 'Заказ документов',
      href: '/service'
    },
    {
      name: section.value.name,
      href: '/service/' + section.value.alias
    }
  ]
}

onMounted(async () => {
  section.value = await loadSection()
  outLinks.value = await loadOutLinks()
})
watch(() => route.path, async () => {
  section.value = await loadSection()
  outLinks.value = await loadOutLinks()
})

const modal = inject('stateModal')

function openModal() {
  modal.value = {
    state: true,
    name: section.value.name
  }
}

function modalOpen() {
  openScheme.value = true
}
function closeScheme(){
  openScheme.value = false
}

</script>

<template>
  <Breadcrumbs :outLinks="outLinks"/>
  <section class="container certification">
    <h1 class="page-title">{{ section.name }}</h1>
    <div class="undertitle-text">
      <p>{{ section.short_content }}</p>
      <button @click="openModal" class="button button--dark-blue" data-modal-open="contact-us"
      >Консультация</button>
    </div>
    <div>
      <div class="certification__group">
        <ul class="certification__list">
          <li v-for="item in section.items" :key="item.id">
            <RouterLink :to="'/section/'+ section.alias + '/' + item.alias">
              <p>{{ item.name }}</p>
            </RouterLink>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">


.certification__list {
  display: flex;
}



.certification__group {
  padding-top: 10px;
}

.btn {
  max-width: 360px;
  margin: 5px auto;
  padding: 10px;
  text-align: center;
  background-color: #6388a8;
  cursor: pointer;
  color: white;
  font-size: 20px;
  border-radius: 15px;
}
</style>
