<script setup>
import {inject, ref, computed, defineProps, watch} from 'vue'
import {useVuelidate} from '@vuelidate/core'
import { required, email, minLength, helpers, } from '@vuelidate/validators'
import orderService from "@/services/order.service";
import { useYandexMetrika } from 'yandex-metrika-vue3'

const yandexMetrika = useYandexMetrika()

const props = defineProps({
  dataModal:{
    type: Object
  }
})

const stateModal = inject('stateModal')

const doneOrder = ref(false)

function closeModal(){
  stateModal.value = false
  doneOrder.value = false
  if(process.env.NODE_ENV !== 'development'){
    yandexMetrika.reachGoal('closeForm')
  }

}

const order = ref({
  name: '',
  phone: '',
  email: '',
  content: '',
  product: ''
})

const maskPhone = {
  mask: '+{7}(000) 000-0000',
  lazy: true
}

const validations = computed(() => {
    return {
    name: {
      required:helpers.withMessage('Обязательно к заполнению', required),
      minLength:helpers.withMessage('От трех знаков', minLength(3))
    },
    phone: {
      required:helpers.withMessage('Обязательно к заполнению', required),
    },
    email: {
      /*required:helpers.withMessage('Обязательно к заполнению', required),*/
      email:helpers.withMessage('Неправильный формат', email)
    },
    content: {
      required:helpers.withMessage('Обязательно к заполнению', required),
    }
  }
})

const v$ = useVuelidate(validations, order)

async function sendForm(){
  const isFormCorrect = await v$.value.$validate()

  if (!isFormCorrect) return
  order.value.product = stateModal.value.name
  const result = await orderService.post(order.value)
  if(result){
    doneOrder.value = true
    if(process.env.NODE_ENV !== 'development'){
      yandexMetrika.reachGoal('sendOrder')
    }
  }

}

watch(()=> stateModal.value, () => {
  order.value.product = stateModal.value.name
})

</script>

<template>
  <!-- Модальное окно Напишите нам-->
  <div :class="['modal', 'modal--contact-us', {'is-active': stateModal}]" data-modal="contact-us">
    <div class="modal__wrapper">
      <div class="modal__overlay" data-close-modal></div>
      <div class="modal__content" v-if="doneOrder">
        <h3 class="page-title">Ваша заявка принята, в ближайшее время мы с вами свяжемся</h3>
        <button @click="closeModal" class="modal__close-btn btn-reset" type="button" aria-label="Закрыть попап" data-close-modal>
          <svg width="27" height="27">
            <use href="#icon-cross"></use>
          </svg>
        </button>
      </div>
      <div class="modal__content" v-else>
        <h3 class="page-title">Получить консультацию</h3>
        <form v-on:submit.prevent="sendForm" class="form modal__form">
          <div class="form__fields-wrapper">
            <!-- если поле с ошибкой, вешать на label класс error-field и показывать спан с текстом ошибки error-field__text -->
            <label :class="['form__field', {'error-field': order.name.length === 0 }]">
              <input :class="{'filled': order.name.length > 0}"  v-model="order.name" type="text" name="name">
              <span class="form__field-placeholder">Имя</span>
              <span v-if="v$.name.$dirty && v$.name.required.$invalid" class="error-field__text">
                 {{ v$.name.required.$message }}
              </span>
            </label>
            <label :class="['form__field', {'error-field': order.phone.length === 0 }]">
              <input :class="{'filled': order.phone.length > 0}"
                     v-model="order.phone"
                     v-imask="maskPhone"
                     type="tel"
                     name="tel">
              <span class="form__field-placeholder">Номер телефона</span>
              <span v-if="v$.phone.$dirty && v$.phone.required.$invalid" class="error-field__text">
                 {{ v$.phone.required.$message }}
              </span>
            </label>
          </div>
          <label :class="['form__field', {'error-field': order.content.length === 0 }]">
            <textarea :class="{'filled': order.content.length > 0}" v-model="order.content" name="description" class="form__field"></textarea>
            <span class="form__field-placeholder">Добавить описание</span>
            <span v-if="v$.content.$dirty && v$.content.required.$invalid" class="error-field__text">
                 {{ v$.name.required.$message }}
              </span>
          </label>
          <div class="form__buttons">
            <button class="button button--dark-blue" data-close-modal type="submit">Отправить</button>
            <p>Нажимая эту кнопку Вы соглашаетесь с <a href="#">условиями обработки информации</a></p>
          </div>
        </form>
        <button @click="closeModal" class="modal__close-btn btn-reset" type="button" aria-label="Закрыть попап" data-close-modal>
          <svg width="27" height="27">
            <use href="#icon-cross"></use>
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.form__field.error-field textarea {
  border-color: #e6655e;
}
</style>
