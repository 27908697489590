<script setup>
import {defineProps, onMounted, ref, watch} from 'vue'

const props = defineProps({
    title: String,
    direction: {
      type: String,
      required: true
    }
})

const viewBenefit = ref([])
const show = ref(false)

const benefit = [
    {
      id: 1,
      title: 'Свой аккредитованный орган по сертификации',
      xlink: '#advantage-1',
      href: '',
      direct: 'cert'
    },
    {
      id: 2,
      title: 'Свои аккредитованные лаборатории',
      xlink: '#advantage-2',
      href: '/about/ral',
      direct: 'cert'
    },
    {
      id: 3,
      title: 'Опыт 12 лет успешной работы',
      xlink: '#advantage-3',
      href: '',
      direct: 'cert'
    },
    {
      id: 4,
      title: 'Собственный отдел технических специалистов по регистрации медицинских изделий',
      xlink: '#advantage-4',
      href: '',
      direct: 'registry'
    },
    {
      id: 5,
      title: 'Официальный партнер Минпромторга',
      xlink: '#advantage-5',
      href: '',
      direct: 'cert'
    },
    {
      id: 6,
      title: 'Лучшие тарифы на логистику',
      xlink: '#advantage-6',
      href: '',
      direct: 'cert'
    },
    {
      id: 7,
      title: 'Этапы и сроки фиксируем в договоре',
      xlink: '#advantage-8',
      href: '',
      direct: 'cert'
    },
  {
    id: 8,
    title: 'Личный менеджер всегда на связи и готов ответить на все вопросы',
    xlink: '#advantage-7',
    href: '',
    direct: 'cert'
  },
 {
    id: 9,
    title: 'Работа напрямую с техническим специалистом (не через менеджера)',
    xlink: '#advantage-5',
    href: '',
    direct: 'registry'
  },
   {
    id: 10,
    title: 'Опыт работы с разными классами риска медицинских изделий',
    xlink: '#advantage-3',
    href: '',
     direct: 'registry'
  },
  {
    id: 11,
    title: 'Максимальная прозрачность на протяжении всей процедуры регистрации',
    xlink: '#advantage-1',
    href: '',
    direct: 'registry'
  },
  {
    id: 12,
    title: 'Работаем только с аккредитованными лабораториями',
    xlink: '#advantage-2',
    href: '',
    direct: 'registry'
  }
  ]

onMounted(() => {
    show.value = false
    viewBenefit.value = randomElement(6)
    if(viewBenefit.value.length > 0){
      show.value = true
    }
})

watch(() => props.direction, () => {
  viewBenefit.value = randomElement(6)
  if(viewBenefit.value.length > 0){
    show.value = true
  }
  if(viewBenefit.value.length === 0){
      show.value = false
  }
})

function randomElement(num){
  let shuffleAr = benefit.filter((item) => {
      if(props.direction === 'registry' && item.direct === 'registry'){
          return item
      }
    if(props.direction === 'certificate' && item.direct === 'cert'){
      return item
    }
    if(props.direction === undefined){
      return item
    }
  })
  shuffleAr.sort(() => Math.random() - 0.5)
  return shuffleAr.slice(0, num)
}

</script>

<template>

  <section class="container advantages" v-if="show">
    <h2 v-show="props.title" class="section-title">{{ props.title }}</h2>
    <ul class="advantages__list">
      <li class="advantages__item" v-for="item in viewBenefit" :key="item.id">
        <svg width="60" height="60" fill="#3E3E3E">
          <use :xlink:href="item.xlink"></use>
        </svg>
        <div>
          <h3>{{ item.title }}</h3>
          <RouterLink :to="item.href" v-if="item.href">Подробнее</RouterLink>
        </div>
      </li>
    </ul>
  </section>
</template>

<style scoped lang="scss">

</style>
