export default {
  data () {
      return {
          title: 'Эвеланс',
          basicPhone: '+7(499)303-2353',
          formatBasicPhone: '+74993032353',
          basicEmail: 'msk@evelans.ru',
          tmBot: 'https://t.me/evelans_group',
          address: '129085, Москва г, пр-кт Мира, д. 101, стр. 1, помещение 1'
      }
  }
}
