<script setup>
import Breadcrumbs from "@/components/Breadcrumbs";
import Reference from "@/components/Reference.vue";
import {inject, onMounted, ref, watch} from 'vue'
import {useRoute} from 'vue-router'
import sectionService from "@/services/section.service";
import BenefitsBlock from "@/components/BenefitsBlock.vue";
import Standard from "@/components/Standard.vue";
import ModalWindow from "@/layouts/ModalWindow.vue";
import SchemeBlock from "@/components/scheme/SchemeBlock.vue";
import QuoteBlock from "@/components/block/QuoteBlock.vue";
import AlertBlock from "@/components/block/AlertBlock.vue";
import SlidingBlock from "@/components/block/SlidingBlock.vue";
import DefaultBlock from "@/components/block/DefaultBlock.vue";
import ButtonOrder from "@/components/ui/ButtonOrder.vue";
import ButtonScheme from "@/components/ui/ButtonScheme.vue";
import SchemeLight from "@/components/scheme/SchemeLight.vue";
import SchemeCovid from "@/components/scheme/SchemeCovid.vue";

const route = useRoute()
const outLinks = ref([])
const reference = ref([])

const showDoc = ref(false)

const modal = inject('stateModal')

function openModal() {
  modal.value = {
    state: true,
    name: document.value.name
  }
}

const document = ref(false)
const content = ref(false)
const direction = ref('')
const blockOne = ref({})
const blockDue = ref({})
const blockTree = ref({})
const blockFor = ref({})
const loadDocument = async () => await sectionService.oneDocument(route.params.alias)

async function loadOutLinks() {
  return [
    {
      name: document.value.parent.name,
      href: '/section/' + document.value.parent.alias
    },
    {
      name: document.value.name,
      href: document.value.alias
    }
  ]
}

onMounted(async () => {
  document.value = await loadDocument()
  outLinks.value = await loadOutLinks()
  reference.value = document.value.reference
  direction.value = document.value.parent.alias
  let check = document.value.infoBlock.find((item) => item.point === 'one')
  if (check !== undefined) {
    blockOne.value = check
  }
  check = document.value.infoBlock.find((item) => item.point === 'due')
  if (check !== undefined) {
    blockDue.value = check
  }
  check = document.value.infoBlock.find((item) => item.point === 'tree')
  if (check !== undefined) {
    blockTree.value = check
  }
  check = document.value.infoBlock.find((item) => item.point === 'for')
  if (check !== undefined) {
    blockFor.value = check
  }

  tab.value = document.value.textBook.find((item, key) => key === 0)

})

watch(() => route.path, async () => {
  document.value = await loadDocument()
  outLinks.value = await loadOutLinks()
  reference.value = document.value.reference
  direction.value = document.value.parent.alias
  resetBlock()
  let check = document.value.infoBlock.find((item) => item.point === 'one')
  if (check !== undefined) {
    blockOne.value = check
  }
  check = document.value.infoBlock.find((item) => item.point === 'due')
  if (check !== undefined) {
    blockDue.value = check
  }
  check = document.value.infoBlock.find((item) => item.point === 'tree')
  if (check !== undefined) {
    blockTree.value = check
  }
  check = document.value.infoBlock.find((item) => item.point === 'for')
  if (check !== undefined) {
    blockFor.value = check
  }
  tab.value = document.value.textBook.find((item, key) => key === 0)
})

function resetBlock() {
  blockOne.value = {}
  blockDue.value = {}
  blockTree.value = {}
  blockFor.value = {}
}

const tab = ref({})

function changeTab(id) {
  tab.value = document.value.textBook.find(item => item.id === id)
}


const sectionScheme = [
  'registration_medical_national_rules', 'simplified_registration', 'expedited_registration'
]

function avaiButton() {
  let query = route.path.split('/')
  let lastPath = query[query.length - 1]
  let num = sectionScheme.indexOf(lastPath)
  if (num >= 0) {
    return true
  }
  return false
}

const openScheme = ref(false)

const nameSchemeBlock = ref('')

function modalOpen(val) {
  nameSchemeBlock.value = val
  openScheme.value = true
}

function closeScheme() {
  openScheme.value = false
}

function changeComponent(block) {
  if (Object.keys(block).length === 0) {
    return DefaultBlock
  }
  if (block.block === 'alert') {
    return AlertBlock
  } else if (block.block === 'sliding') {
    return SlidingBlock
  } else if (block.block === 'quote') {
    return QuoteBlock
  }
  return DefaultBlock
}

</script>

<template>
  <section class="service">
    <Breadcrumbs :out-links="outLinks"/>
    <div class="container service__container">
      <h1 class="page-title">{{ document.name }}</h1>
      <div class="service__buttons">
        <ButtonOrder @open="openModal" :path="route.path"></ButtonOrder>
      </div>
      <div class="undertitle-text" v-html="document.short_content">
      </div>

      <div class="bookmark">
        <div :class="['bookmark--item', {active: item.id === tab.id}]" v-for="item in document.textBook" :key="item.id">
          <div @click="changeTab(item.id)" class="bookmark_nav button button--square">
            {{ item.bookmark }}
          </div>
        </div>
      </div>
      <div v-show="tab.content" class="content" v-html="tab.content">
      </div>

      <component v-if="Object.keys(blockOne).length !== 0" :is="changeComponent(blockOne)" :item="blockOne"></component>
      <component :is="changeComponent(blockDue)" :item="blockDue"></component>

      <ButtonScheme :path="route.path" @open="modalOpen" v-if="avaiButton()"/>

      <div class="service__description" v-html="document.full_content">
      </div>
      <div style="text-align: center">
        <ButtonOrder @open="openModal" :path="route.path"></ButtonOrder>
      </div>
      <component :is="changeComponent(blockTree)" :item="blockTree"></component>
      <Standard v-if="route.path.includes('certificate_trts')"/>
      <BenefitsBlock :title="'ПОЧЕМУ НАМ ДОВЕРЯЮТ'" :direction="direction"/>
      <component :is="changeComponent(blockFor)" :item="blockFor"></component>
      <div class="service__buttons">
        <ButtonOrder @open="openModal" :path="route.path"/>

        <button v-if="showDoc" @click="openModal" class="button button--white">Просмотр документа</button>
      </div>
      <Reference :reference="reference"/>
    </div>
  </section>
  <section>
    <ModalWindow :open="openScheme" @close="closeScheme">
      <SchemeBlock v-if="nameSchemeBlock === 'SchemeBlock'"/>
      <SchemeLight v-if="nameSchemeBlock === 'SchemeLight'"/>
      <SchemeCovid v-if="nameSchemeBlock === 'SchemeCovid'"/>
    </ModalWindow>
  </section>
</template>

<style lang="scss">
.service {
  background-color: unset;
}

.service .page-title {}

.service__buttons {
  justify-content: end;

  button {
    width: 50%;
    @media (max-width: 767px) {
      width: 80%;
    }
  }
}


.bookmark {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

.bookmark--item {
  flex-grow: 1;
  .bookmark_nav {
    background-color: #f2f2f2;
    color: #6388a8;
    border: 1px solid #638aa8;
    border-bottom: unset;
  }
}

.bookmark .active {
  .bookmark_nav {
    color: #f2f2f2;
    background-color: #6388a8;
  }
}

.bookmark_nav {
  padding: 10px;
  border-radius: unset;
  cursor: pointer;
  margin-left: 2px;
}
.bookmark .bookmark_nav:first-child{
  border-top-left-radius: 15px;
}
.bookmark .bookmark_nav:last-child{
  border-top-right-radius: 15px;
}

.button--square {
  padding: 30px 35px;
}

.content {
  background-color: #f2f2f2;
  color: #6388a8;
  box-shadow: 3px 10px 10px 0 rgba(0, 0, 0, .15), -5px -4px 35px 0 #fff;
  padding: 15px;
  margin: 20px 0;
  border-left: 3px #e6655e solid;
}
.content ul li {
  list-style-type: disc;
  color: #4f718f;
}

.team__item {
  cursor: pointer;
}

.btn {
  max-width: 360px;
  margin: 5px auto;
  padding: 10px;
  text-align: center;
  background-color: #6388a8;
  cursor: pointer;
  color: white;
  font-size: 20px;
  border-radius: 15px;
}
</style>
