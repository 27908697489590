<script setup>
import {ref, defineEmits, defineProps, onMounted, watch} from 'vue'
import {useYandexMetrika} from "yandex-metrika-vue3";

const nameButton = ref('Перезвоните мне')
const yandexMetrika = useYandexMetrika()
const props = defineProps({
  path:{
      default: '',
      type: String
  },
  name:{
    type: String,
    default: ''
  },
  classColor:{
    type: String,
    default: 'button--white'
  }
})

onMounted(() => {
  nameButton.value = changeName(props.path)
})

watch(() => props.path, () => {
  nameButton.value = changeName(props.path)
})

function changeName(path){

  if(props.name.length > 1){
    return props.name
  }

  let query = props.path.split('/')
  let lastPath = query[query.length - 1]

  if(lastPath === 'certificate_trts' || lastPath === 'voluntary_certification' || lastPath === 'quality_management_system_certification_iso'){
      return 'Получить расчет стоимости'
  }
  if(lastPath === 'carrying_tests'){
    return 'Получить консультацию'
  }
  if(lastPath === 'development_safety' || lastPath === 'development_passport' || lastPath === 'development_instruction_manual'
  || lastPath === 'development_technical_specifications' ){
    return 'Запросить условия'
  }
  if(lastPath === 'certificate_registration_rospotrebnazor'){
    return 'Заказать расчет стоимости'
  }
  if(lastPath === 'registration_medical_national_rules' || lastPath === 'registration_rules_eaeu'){
    return 'Получить консультацию по срокам и цене'
  }

  return 'Получить консультацию'
}

const emits = defineEmits([
  'open'
])

function openModal(){
  yandexMetrika.reachGoal('openForm')
    emits('open')
}

</script>

<template>
  <button @click="openModal" :class="['button', 'button--border', classColor]" data-modal-open="contact-us">{{ nameButton }} </button>
</template>

<style scoped lang="scss">
 .button{
   margin: 10px 0;
   font-size: 24px;
   font-weight: bold;
 }
</style>
