<script setup>

import teamService from "@/services/team.service";
import {onMounted, ref} from "vue";

const titlePage = 'Вакансии'
const idVacancy = ref(0)
const loadVacancy = async () => await teamService.vacancies()
const vacancy = ref([])

onMounted(async () => {
  vacancy.value = await loadVacancy()
})

function openContent(id) {
  idVacancy.value = id
}

</script>

<template>
  <section class="container vacancies">
    <h2 class="section-title">{{ titlePage }}</h2>
    <ul class="vacancies__list">
      <li class="vacancies__item" v-for="item in vacancy" :key="item.id">
        <div>
          <h3>{{ item.position }}</h3>
          <a @click="openContent(item.id)">Подробнее</a>
        </div>
        <div :class="{hidden:idVacancy!= item.id}">
          <h3>{{ item.position }}</h3>
          <p v-html="item.content"></p>
        </div>
      </li>
    </ul>
  </section>
</template>

<style scoped lang="scss">
  .hidden{
      display: none;
  }
  .vacancies__item{
     margin-bottom: 30px;
  }
</style>
