<script setup>
import {ref, defineEmits} from 'vue'
import {useYandexMetrika} from "yandex-metrika-vue3";


const title = 'Аккредитованный орган сертификации'

const yandexMetrika = useYandexMetrika()
const introTitle = 'Помощь <span>в получении</span> <span class="subtitle">регистрационного удостоверения на медизделия</span>'
const introDop = 'Экспертное сопровождение на всех этапах'

const emits = defineEmits(['open'])

function openModal(){
  yandexMetrika.reachGoal('openForm')
    emits('open')
}


const intro = ref(false)
window.addEventListener('scroll', function () {
  if (window.pageYOffset > 10) {
      intro.value = true
  } else {
     intro.value = false
  }
})

//const nameButton = 'Получить расчет стоимости и сроков'
const nameButton = 'Рассчитайте цену регистрации'
</script>

<template>
  <section :class="['intro', {'scrolled': intro}]" :style="{ backgroundImage: 'url(/img/roszdravnadzor.svg)'}">

    <div class="container intro__container">
      <p class="intro-text">{{ $t('title') }}</p>
      <h2 class="intro__title" v-html="introTitle"></h2>
      <h4 class="intro__title-due">{{ introDop }}</h4>
      <button @click="openModal" class="button button--red button--border" data-modal-open="contact-us">{{ nameButton }}</button>
    </div>
  </section>
</template>

<style scoped lang="scss">

  .intro{
    background-position: center right;
    background-size: 400px;
    background-repeat: no-repeat;
  }
  @media(max-width:767px){
     .intro{
       background-size: 100px;
       background-position: top right;
     }
  }
</style>
