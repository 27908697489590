<script setup>

</script>

<template>
  <section class="container about">
      <h1>Страница не найдена</h1>
    Попробуйте  <a href="/">начать сначала</a>
  </section>
</template>

<style scoped lang="scss">

</style>
