<script setup>
import {onMounted, ref} from 'vue'
import standardService from "@/services/standard.service";
import IconSvg from "@/components/ui/IconSvg.vue";

const loadStandard = async () => await standardService.list()

const standard = ref([])

onMounted(async () => {
  standard.value = await loadStandard()
})

const checkStandard = ref(0)

function openStandart(key) {
  if (checkStandard.value === key) {
    checkStandard.value = 0
  } else {
    checkStandard.value = key
  }

}


</script>

<template>
  <h3>Органы и лаборатории компании Эвеланс аккредитованы на следующие технические регламенты таможенного союза:</h3>
  <div v-for="item in standard" :key="item.id">
    <div class="standard__block" @click="openStandart(item.id)">
      <IconSvg  size="30" icon="icon-object-22" fill="white" :background="{size:15, color:'#4f718f'}"/>
      <a >{{ item.short_name + ' ' + item.name }}</a>
    </div>
    <div class="standart_content" v-if="item.id === checkStandard" v-html="item.content"></div>
  </div>
</template>

<style scoped lang="scss">
.standard__block {
  padding: 10px;
  margin-bottom: 5px;
  cursor: pointer;
  border-radius: 15px;
  background-color: #f2f2f2;
  color: #6388a8;
  box-shadow: 3px 10px 10px 0 rgba(0, 0, 0, .15), -5px -4px 35px 0 #fff;
  display: flex;
  align-items: center;
}
.standard__block a {
  margin-left: 20px;
}

.standart_content {
  padding: 10px;
  margin: 2px 5px 15px 5px;
  border: 1px solid #6388a8;
  border-left-color: #e6655e;
  border-radius: 10px;
  box-shadow: 3px 10px 10px 0 rgba(0, 0, 0, .15), -5px -4px 35px 0 #fff;
}

</style>
