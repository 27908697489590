<script setup>
import {onMounted, ref} from 'vue'
import Breadcrumbs from "@/components/Breadcrumbs";
import teamService from "@/services/team.service";
import Swiper from "swiper"
import { Navigation } from 'swiper/modules'
import VacanciesList from '@/components/VacanciesList'

const titlePage = 'Команда Эвеланс'

const outLinks = [
  {
    name: 'Команда',
    href: 'employee'
  }
]

const loadEmployee = async () => await teamService.employee()
const employee = ref([])

onMounted(async () => {
  employee.value = await loadEmployee()
  goSwipper()
})

function fullPath(photo){
    return process.env.VUE_APP_URL_SITE + 'images/employee/'+ photo
}

function goSwipper(){
  if (document.body.clientWidth > '767') {
    const teamSliderContainer = document.querySelector('.team__slider-container');
    if (teamSliderContainer) {
      const prevBtn = teamSliderContainer.querySelector('.slider-btn--prev');
      const nextBtn = teamSliderContainer.querySelector('.slider-btn--next');
      new Swiper(teamSliderContainer, {
        slidesPerView: 1,
        spaceBetween: 0,
        modules: [ Navigation ],
        direction: 'horizontal',

        navigation: {
          nextEl: nextBtn,
          prevEl: prevBtn,
        },

        breakpoints: {

          768: {
            slidesPerView: 2,
            spaceBetween: 25,
          },

          900: {
            slidesPerView: 3,
            spaceBetween: 25,
          },

          1024: {
            slidesPerView: 4,
            spaceBetween: 25,
          }
        },
      });
    }
  }
}


</script>

<template>
  <Breadcrumbs :out-links="outLinks"/>
  <section class="container team">
    <h1 class="page-title">{{ titlePage }}</h1>
    <div class="team__slider-container swiper-container">
      <div class="slider-controls">
        <a class="slider-btn slider-btn--prev swiper-button-prev">
          <svg width="26" height="26">
            <use xlink:href="#icon-arrow"></use>
          </svg>
        </a>
        <a class="slider-btn slider-btn--next swiper-button-next">
          <svg width="26" height="26">
            <use xlink:href="#icon-arrow"></use>
          </svg>
        </a>
      </div>
      <ul class="team__slider swiper-wrapper">
        <li class="team__item swiper-slide"
        v-for="item in employee" :key="item.id">
          <img :src="fullPath(item.photo)" width="261" height="370" alt="Фото специалиста">
          <span>{{ item.position }}</span>
          <p>{{ item.full_name }}</p>
        </li>
      </ul>
    </div>
  </section>
  <VacanciesList />
</template>

<style scoped lang="scss">

</style>
