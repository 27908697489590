<script setup>
import Swiper from "swiper"
import { Navigation } from 'swiper/modules'
import {onMounted, ref} from "vue";
import ralService from "@/services/ral.service";
import router from "@/router";

const loadRal = async () => ralService.index()

const listRal = ref([])



onMounted(async () => {
  listRal.value = await loadRal()
  goSwiper()
})

async function goSwiper(){
  const partnerSliderContainer = document.querySelector('.partner__slider-container');

  if (partnerSliderContainer) {
    const prevBtn = partnerSliderContainer.querySelector('.slider-btn--prev');
    const nextBtn = partnerSliderContainer.querySelector('.slider-btn--next');
    new Swiper(partnerSliderContainer, {
      slidesPerView: 1,
      spaceBetween: 0,
      modules: [Navigation],
      direction: 'horizontal',

      navigation: {
        nextEl: nextBtn,
        prevEl: prevBtn,
      },

      breakpoints: {
        500: {
          slidesPerView: 2,
          spaceBetween: 20,
        },

        768: {
          slidesPerView: 3,
          spaceBetween: 20,
        },

        1024: {
          slidesPerView: 4,
          spaceBetween: 20,
        }
      },
    });
  }
}

function fullPath(name){
  return process.env.VUE_APP_URL_SITE + '/images/ral/'+ name
}

function reSize(content){
    return content.slice(0, 100) + '...'
}

function goLaborant(id){
  router.push({ name: 'ral', query: {id: id} })
}


</script>

<template>
  <section class="container partner">
    <h2 class="section-title">Мы официальный партнер крупнейших лабораторий в России</h2>
    <div class="partner__slider-container swiper-container">
      <div class="slider-controls">
        <a class="slider-btn slider-btn--prev swiper-button-prev" href="#prev">
          <svg width="26" height="26">
            <use xlink:href="#icon-arrow"></use>
          </svg>
        </a>
        <a class="slider-btn slider-btn--next swiper-button-next" href="#next">
          <svg width="26" height="26">
            <use xlink:href="#icon-arrow"></use>
          </svg>
        </a>
      </div>
      <ul class="partner__slider swiper-wrapper">
        <li class="partner__item swiper-slide" v-for="item in listRal" :key="item.id">
          <div @click="goLaborant(item.id)">
            <img :src="fullPath(item.logo)" width="124" height="34" alt="Логотип">
          </div>
          <p>{{ reSize(item.content) }}</p>
        </li>
      </ul>
    </div>
  </section>
</template>

<style scoped lang="scss">

</style>
