<script setup>
import {computed, defineProps, inject, onMounted, ref} from 'vue'
import {email, helpers, minLength, required} from "@vuelidate/validators";
import {useVuelidate} from "@vuelidate/core";
import orderService from "@/services/order.service";
import {useYandexMetrika} from "yandex-metrika-vue3";

const yandexMetrika = useYandexMetrika()
const stateDone = inject('stateDone')
const quiz = [
  {
    'key': 1,
    'name': 'target',
    'active': true,
    'question': 'Для какой цели Вам нужны документы? ',
    'answers': [
      'Для прохождения таможни (импорт)',
      'Для поставки продукции в торговые сети',
      'Для участия в тендерах и конкурсах',
      'Для выхода на зарубежные рынки (экспорт)',
      'Для подтверждения качества продукции или услуг',
      'Для поставки на маркетплейсы (OZON, WB, Я.Маркет)',
    ],
    'required': true,
  },
  {
    'key': 2,
    'name': 'product',
    'active': false,
    'question': 'Выберите, на что необходимо получить документы?',
    'answers': [
      'Продукцию производства РФ',
      'Импортную продукцию',
      'Услуги',
      'Нужно несколько разных документов'
    ],
    'required': true
  },
  {
    'key': 3,
    'active': false,
    'question': 'Уточните пожалуйста какая у вас продукция?',
    'answers': [],
    'required': false
  },
  {
    'key': 4,
    'name': 'term',
    'active': false,
    'question': 'Выберите, когда Вам нужно получить документы?',
    'answers': [
      '7дней', '14дней', '1месяц', 'более 1 месяца'
    ],
    'required': true
  },
  {
    'key': 5,
    'name': 'form',
    'active': false,
    'question': 'От какого лица реализуете или планируете реализовывать товар? ',
    'answers': [
      'Юридическое лицо', 'ИП'
    ],
    'required': true
  },
  {
    'key': 6,
    'name': 'contact',
    'active': true,
    'question': 'Где Вам удобней получить расчет и консультацию?',
    'answers': [
      'Email', 'Telegram', 'Whatsapp', 'Телефон'
    ],
    'required': true
  }
]

const props = defineProps({
    visibleModal: {
      type: Boolean
    }
  }
)
const emits = defineEmits(['close'])
const activeStep = ref(0)

const viewQuiz = computed(() => {
  return quiz.filter(item => item.active === true)
})

function haveActiveStep() {
  activeStep.value = Number(localStorage.getItem('activeStep'))
}
function setActiveStep(value) {
  localStorage.setItem('activeStep', value)
  yandexMetrika.reachGoal('step_'+ activeStep.value)
  haveActiveStep()
}

const contentQuiz = ref([])

const order = ref({
  name: '',
  phone: '',
  email: '',
  content: '',
  product: '',
  jobs: []
})

const title = 'Ответьте на ' + viewQuiz.value.length +  ' вопроса, чтобы получить расчет и скидку '
const discount = 15

const maskPhone = {
  mask: '+{7}(000) 000-0000',
  lazy: true
}

const validations = computed(() => {
  return {
    name: {
      required: helpers.withMessage('Обязательно к заполнению', required),
      minLength: helpers.withMessage('От трех знаков', minLength(3))
    },
    phone: {
      required: helpers.withMessage('Обязательно к заполнению', required),
    },
    email: {
      /*required:helpers.withMessage('Обязательно к заполнению', required),*/
      email: helpers.withMessage('Неправильный формат', email)
    }
  }
})

const v$ = useVuelidate(validations, order)

function closeModal(){
  setActiveStep(0)
  emits("close")

  if(process.env.NODE_ENV !== 'development'){
    yandexMetrika.reachGoal('closeForm')
  }
}

async function sendForm(){
  const isFormCorrect = await v$.value.$validate()

  if (!isFormCorrect) return
  order.value.content = contentQuiz.value.join('\n')
  const result = await orderService.post(order.value)
  if(result){

    closeModal()
    stateDone.value = true
    if(process.env.NODE_ENV !== 'development'){
      yandexMetrika.reachGoal('sendOrder')
    }
    contentQuiz.value = []
    setActiveStep(0)
    order.value.content = ''
    order.value.product = ''
  }

}

onMounted(() => {
  if(localStorage.getItem('activeStep') === undefined ) {
    setActiveStep(0)
  }
  haveActiveStep()
})


const settingQuiz = {
    buttonPrev: false
}

function getWidth() {
  return (100/viewQuiz.value.length * activeStep.value)
}

function titleProgress() {

    if(activeStep.value === 0){
      return 'Буквально пара вопросов для расчета'
    }
    if(activeStep.value >= 1 && activeStep.value != viewQuiz.value.length ){
        return 'Фиксируем скидку: ' + activeStep.value * 3 + '%'
    }
    if(activeStep.value === viewQuiz.value.length){
        return 'Готово, отправьте форму'
    }
}

function getDiscount() {
   return  discount/viewQuiz.value.length*activeStep.value
}

</script>

<template>
  <div :class="['modal', 'modal--contact-us', {'is-active': props.visibleModal}]">
    <div class="modal__wrapper">
      <div class="modal__overlay" data-close-modal></div>
      <div class="modal__content">
        <h3 v-if="viewQuiz.length == activeStep" class="page-title">
            Оставьте свой телефон (на него фиксируется скидка)
        </h3>
        <h3 v-else class="page-title">{{ title + ' ' + discount + '%' }}</h3>
        <div>
          <div class="quiz_progress"></div>
          <div class="progress-bar" :style="{width: `${getWidth()}%`}"></div>
        </div>
        <div class="cycle" v-for="( item, key ) in viewQuiz" :key="key">
          <div v-show="key === activeStep">
            <div class="head_quiz">
              {{ item.question }}
                <div class="required" v-if="item.required === false">* по желанию(можно пропустить)</div>
            </div>
            <ul v-if="item.answers.length > 0" class="answers">
              <li v-for="( answer, i ) in item.answers" :key="i">
                <input type="checkbox" v-model="contentQuiz" :value="item.name + ':' + answer">
                {{ answer }}
              </li>
            </ul>
            <div v-else class="form form__field">
              <input type="text" v-model="order.product">
            </div>
            <div class="buttons">
              <button v-show="(activeStep > 0 && settingQuiz.buttonPrev === true)" @click="setActiveStep(--activeStep)" class="button button--white button--border">Назад</button>
              <button @click="setActiveStep(++activeStep)" class="button button--white button--border">Далее</button>
            </div>

          </div>
        </div>

        <div v-show="activeStep == viewQuiz.length">
          <form v-on:submit.prevent="sendForm" class="form modal__form">
          <div style="height: 25px">
          </div>
          <div class="form__fields-wrapper">
            <label :class="['form__field', {'error-field': order.name.length === 0 && v$.name.$dirty}]">
              <input :class="{'filled': order.name.length > 0}" v-model="order.name" type="text" name="name">
              <span class="form__field-placeholder">Имя</span>
              <span v-if="v$.name.$dirty && v$.name.required.$invalid" class="error-field__text">
                 {{ v$.name.required.$message }}
              </span>
            </label>
            <label :class="['form__field', {'error-field': order.phone.length === 0 && v$.phone.$dirty}]">
              <input :class="{'filled': order.phone.length > 0}"
                     v-model="order.phone"
                     v-imask="maskPhone"
                     type="tel"
                     name="tel">
              <span class="form__field-placeholder">Номер телефона</span>
              <span v-if="v$.phone.$dirty && v$.phone.required.$invalid" class="error-field__text">
                 {{ v$.phone.required.$message }}
              </span>
            </label>
          </div>
          <div class="buttons">
            <button v-show="activeStep > 0 && settingQuiz.buttonPrev === true" @click="setActiveStep(--activeStep)" class="button button--white button--border">Назад</button>
            <button class="button button--white button--border">Отправить</button>
          </div>
          </form>
        </div>
        <button @click="closeModal" class="modal__close-btn btn-reset" type="button" aria-label="Закрыть попап"
                data-close-modal>
          <svg width="27" height="27">
            <use href="#icon-cross"></use>
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .page-title{
    color: #2E5E80; // #8cadc5;
    font-weight: 600;
    font-size: 32px;
  }
  .head_quiz{
    text-align: center;
    font-size: 24px;
    color: #2E5E80;
    border-bottom: 1px solid #748694;
    @media (max-width: 480px) {
      font-size: 20px;
    }
  }
  .answers {
    display: flex;
    flex-wrap: wrap;
      @media (max-width: 480px){
        padding-left: 10px;
      }
      li {
        width: 50%;
        list-style-type: none;
        margin-bottom: 10px;
        font-size: 18px;
        color: #324D60;
        @media (max-width: 480px) {
          width: 100%;
        }
      }
  }
  .buttons{
    margin-top: 10px;
    display: flex;
    justify-content: space-around;

    button {
        flex-grow: 1;
        margin: 0 5px;
    }
  }
  .form {
    margin-top: 20px;
  }
  .required {
      font-size: 14px;
      color: red;
      width: 100%;
      text-align: right;
  }
  .progress-bar{
    display: flex;
    background-color: red;
    height: 3px;
    margin-bottom: 20px;
  }
  .quiz_progress {
    text-align: right;
    font-size: 14px;
    margin-bottom: 15px;
    color: #2E5E80;
  }
</style>
