<script setup>

import {defineProps, ref} from 'vue'

const props = defineProps({
  item: Object
})

const show = ref(false)

function openBody(){
    show.value = !show.value
}

</script>

<template>
  <div class="news__description">
    <div class="news__sliding">
      <div @click="openBody" class="header" v-html="props.item.title"></div>
      <div class="body" v-html="props.item.body" v-if="show">
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.news__sliding {
  border: 1px solid #6388a8;
  border-radius: 5px;

  .header {
    padding: 10px 25px;
    background-color: #6388a8;
    font-size: 20px;
    font-weight: bold;
    color: white;
    cursor: pointer;

    &::after{
      content: "+";
      float: right;

    }
  }

  .body{
      padding: 20px;
  }
}
</style>
