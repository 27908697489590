<script setup>
import {defineProps, ref, defineEmits, onMounted, watch} from 'vue'

const props = defineProps({
  path: {
    type: String
  }
})
const emits = defineEmits([
  'open'
])

const nameButton = ref('Схема')

onMounted(() => {
  nameButton.value = changeName(props.path)
})

watch(() => props.path, () => {
  nameButton.value = changeName(props.path)
})

function changeName(path){
  let query = props.path.split('/')
  let lastPath = query[query.length - 1]

  if(lastPath === 'registration_medical_national_rules'){
    return 'Этапы и сроки регистрации РУ (схема)'
  }
  if(lastPath === 'making_changes_registration_dossier'){
      return 'Этапы и сроки внесения изменений (схема)'
  }
  if(lastPath === 'simplified_registration'){
      return 'Этапы и сроки упрощенной регистрации (схема)'
  }
  if(lastPath === 'expedited_registration'){
    return 'Этапы и сроки ускоренной регистрации (схема)'
  }
  return ''
}

function modalOpen(){
  let query = props.path.split('/')
  let lastPath = query[query.length - 1]
  let name = ''
  if(lastPath === 'registration_medical_national_rules'){
      name = 'SchemeBlock'
  }
  if(lastPath === 'simplified_registration'){
    name = 'SchemeLight'
  }
  if(lastPath === 'expedited_registration'){
      name = 'SchemeCovid'
  }
  emits('open', name)
}

</script>

<template>
  <div @click="modalOpen" class="btn button button--dark-new" style="max-width: 720px">{{ nameButton }}</div>
</template>

<style scoped lang="scss">
.button--dark-new{
  box-shadow: 6px 10px 2px 0 rgba(0,0,0,.15),-5px -4px 35px 0 #fff;
}
.button--dark-new:hover{
    padding: 15px;
    background-color: #4f718f;
}
</style>
