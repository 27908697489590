import axios from "axios";

const publishService = {
    async goTimeline(slug){
      const { status, data } = await axios(process.env.VUE_APP_URL_API + 'publish-job/' + slug , {
        method: 'GET',
        headers: {
          Origin: 'https://evelans.ru',
          'User-Agent': ' curl/7.51.0',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET,PATCH,OPTIONS',
          'Access-Control-Allow-Headers': 'Origin, Accept, Content-Type, Access-Control-Allow-Origin',
          'Content-Type': 'application/json; charset=UTF-8'
        }
      }).catch(function (error){
          return {}
      })

      return data
    }
}

export default publishService
