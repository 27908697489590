const lightScheme = [
  {
    id: 1,
    name: 'Предоставление документов',
    amount: 0,
    day: 'work'
  },
  {
    id: 2,
    name: 'Все изделия, включенные в перечень: отечественного или импортного производства',
    amount: 0,
    day: 'work'
  },
  {
    id: 3,
    name: 'Оценка полноты и достоверности сведений',
    amount: 15,
    day: 'work'
  },
  {
    id: 4,
    name: 'Оформление заключения о невозможности регистрации изделия',
    amount: 0,
    day: 'work'
  },
  {
    id: 5,
    name: 'Оформление заключения о возможности регистрации изделия',
    amount: 0,
    day: 'work'
  },
  {
    id: 6,
    name: 'Принятие решения об отказе в госрегистрации',
    amount: 0,
    day: 'work'
  },
  {
    id: 7,
    name: 'Принятие решения о регистрации медицинского изделия, внесение сведений в реестр, выдача РУ',
    amount: 7,
    day: 'work'
  },
]

const step = [
  {
    id: 1,
    step: 1,
    visible: true
  },
  {
    id:2,
    step: 2,
    visible: true
  },
  {
    id:3,
    step: 3,
    visible: true
  },
  {
    id:4,
    step: 5,
    visible: true
  },
  {
    id:5,
    step: 7,
    visible: true
  },
  /*{
    id:6,
    step: 6,
    visible: true
  },
  {
    id:7,
    step: 7,
    visible: true
  },*/
]
export  { lightScheme, step }
