<script setup>

</script>

<template>
  <div class="news__description">Тут Видео будет</div>
</template>

<style scoped lang="scss">

</style>
