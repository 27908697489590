<script setup>
import {onMounted, ref} from 'vue'
import blogService from "@/services/blog.service";
import Swiper from "swiper"
import { Navigation } from 'swiper/modules'


const loadTag = async () => await blogService.preview()
const tags = ref([])

const listBlog = ref([])
const loadListBlog = async () => await blogService.list()

onMounted(async () => {
  tags.value = await loadTag()
  listBlog.value = await loadListBlog()
  goSwiper()

} )

function goSwiper(){
  const blogSliderContainer = document.querySelector('.blog__slider-container.swiper-container');
  if(blogSliderContainer){
    const prevBtn = blogSliderContainer.querySelector('.slider-btn--prev');
    const nextBtn = blogSliderContainer.querySelector('.slider-btn--next');
    new Swiper(blogSliderContainer, {
      slidesPerView: 1,
      spaceBetween: 0,
      modules: [ Navigation ],
      direction: 'horizontal',
      navigation: {
        nextEl: nextBtn,
        prevEl: prevBtn,
      },
      breakpoints: {
        768: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 20,
        }
      }
    });
  }
}

async function loadNewBlog(slug){
  listBlog.value = await blogService.list(slug)
}

</script>



<template>
  <section class="container blog" v-if="false">
    <h2 class="section-title">Самое интересное в нашем Блоге</h2>
    <div class="blog__slider-container swiper-container">
      <div class="blog__slider-controls">
        <ul class="blog__tags">
          <li v-for="tag in tags" :key="tag.id">
            <a :href="'#'+ tag.slug" @click="loadNewBlog(tag.slug)">#{{tag.name}}</a>
          </li>
        </ul>
        <div class="slider-controls">
          <a class="slider-btn slider-btn--prev swiper-button-prev">
            <svg width="26" height="26">
              <use xlink:href="#icon-arrow"></use>
            </svg>
          </a>
          <a class="slider-btn slider-btn--next swiper-button-next">
            <svg width="26" height="26">
              <use xlink:href="#icon-arrow"></use>
            </svg>
          </a>
        </div>
      </div>
      <ul class="blog__list swiper-wrapper"
          @swiper="swiper"
      >
        <li class="blog__item swiper-slide" v-for="itemBlog in listBlog" :key="itemBlog.id">
          <RouterLink :to="'/blog/'+ itemBlog.id">
            <span> <i v-for="itemTag in itemBlog.tags" :key="itemTag.id">#{{ itemTag.name }}</i></span>
            <h3>{{ itemBlog.name }}</h3>
            <p>{{ itemBlog.short_content }}</p>
          </RouterLink>
        </li>
      </ul>
    </div>
  </section>
</template>

<style scoped lang="scss">

</style>
