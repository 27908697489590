<script setup>
import {onMounted, ref} from 'vue'
import blogService from "@/services/blog.service";

const loadBlog = async () => await blogService.list()

const blogList = ref([])

onMounted(async () => {
    blogList.value = await loadBlog()
})

const labelSelect = ref(false)
function toogleSelect() {
   labelSelect.value = !labelSelect.value
}

function sortList(sort){
  if(sort === 'asc'){
    blogList.value = blogList.value.sort((itemA, itemB) => itemA.created_at < itemB.created_at)
  }
  if(sort === 'desc'){
    blogList.value = blogList.value.sort((itemA, itemB) => itemA.created_at > itemB.created_at)
  }
  labelSelect.value = false
}


</script>

<template>
  <section class="container blog blog--page">
    <h1 class="page-title">Самое интересное в нашем Блоге</h1>
    <div class="blog__sorting">
      <div :class="['sorting', {active:labelSelect}]">
        <button @click="toogleSelect" class="sorting__header js_open-sorting">сортировать</button>
        <div class="sorting__parameters">
          <label>
            <input type="radio" class="visually-hidden" name="sorting" value="">
            <span @click="sortList('asc')">Сначала новые</span>
          </label>
          <label>
            <input type="radio" class="visually-hidden" name="sorting" value="">
            <span @click="sortList('desc')">Сначала старые</span>
          </label>
        </div>
      </div>
    </div>
    <ul class="blog__list">
      <li class="blog__item" v-for="itemBlog in blogList" :key="itemBlog.id">
          <RouterLink :to="'/blog/'+ itemBlog.id">
            <span><i v-for="tag in itemBlog.tags" :key="tag.id">#{{tag.name}}</i></span>
          <h3>{{ itemBlog.name }}</h3>
           <p>{{itemBlog.created_at}}</p>
          <p>{{ itemBlog.short_content }}</p>
          </RouterLink>
      </li>
    </ul>
<!--    <button class="button button&#45;&#45;white">показать ещё</button>-->
  </section>
</template>

<style scoped lang="scss">

</style>
