<script setup>

import { defineProps } from 'vue'

const props = defineProps({
  item: Object
})

</script>

<template>
  <div class="news__description">
    <div class="news__alert">
        <div class="img_alert">
          <img src="/img/sprite/alert.svg">
        </div>
        <div class="content_alert" v-html="props.item.body"></div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .news__alert {
    border-top: 5px solid #97b7ca;
    border-radius: 5px;
    padding: 20px;
    background-color: #e5e2e2;
    display: flex;
    flex-wrap: nowrap;

    .img_alert{
      margin: 0 30px 0 10px;
    }

    .img_alert img{
       margin: 5px;
        height: unset;
        @media (max-width: 480px) {
          flex-shrink: 0;
          max-width: 30px;
        }
    }
  }
</style>
