<script setup>

</script>

<template>
  <div class="news__description">
    <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3Ab9cdc4ba8d80b4b9941bb02fcb8ea0555924b0d46d4c0bd09e00b290a617235c&amp;source=constructor" width="100%" height="458" frameborder="0"></iframe>
  </div>
  {{ address }}
</template>

<style scoped lang="scss">

</style>
