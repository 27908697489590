<script setup>

</script>

<template>
  <section class="container about">
    <div class="about__intro">
      <div class="about__text">
        <h1 class="page-title">О компании</h1>
        <p>Очень короткое но интересное описание деятельности компании и механизмов работы</p>
      </div>
      <img src="img/about.png" width="458" height="458" alt="" srcset="img/about.png 1x, img/about@2x.png 2x">
    </div>
    <ul class="about__facts">
      <li class="about__fact">
        <svg width="30" height="30">
          <use xlink:href="#icon-about-1"></use>
        </svg>
        <span>5 лет</span>
        <p>успешной работы</p>
      </li>
      <li class="about__fact">
        <svg width="30" height="30">
          <use xlink:href="#icon-about-2"></use>
        </svg>
        <span>50+</span>
        <p>довольных клиентов</p>
      </li>
      <li class="about__fact">
        <svg width="30" height="30">
          <use xlink:href="#icon-about-3"></use>
        </svg>
        <span>350 в год</span>
        <p>лабораторных тестов</p>
      </li>
    </ul>
  </section>
  <section class="container history">
    <h2 class="section-title">История компании</h2>
    <ul class="history__list">
      <li>
        <span>Прошлое</span>
        <p>Ристративных условий. И нет сомнений, что представители современных социальных резервов, вне зависимости от их уровня, должны быть разоблачены. Высокий уровень вовлечения представителей целевой аудитории является четким доказательством простого факта: базовый вектор развития напрямую зависит от переосмысления внешнеэкономических политик. Банальные, но неопровержимые выводы, а также сторонники тоталитаризма в науке освещают чрезвычайно интересные особенности картины в целом, однако конкретные выводы, разумеется, функционально разнесены на независимые элементы. С другой стороны, высококачественный прототип будущего проекта является качественно новой ступенью экономической целесообразности принимаемых решений. Господа, начало повседневной работы по формированию позиции требует анализа глубокомысленных рассуждений.</p>
      </li>
      <li>
        <span>Настоящее</span>
        <p>Но семантический разбор внешних противодействий обеспечивает актуальность новых предложений. Идейные соображения высшего порядка, а также базовый вектор развития выявляет срочную потребность новых предложений.</p>
        <p>Ристративных условий. И нет сомнений, что представители современных социальных резервов, вне зависимости от их уровня, должны быть разоблачены. Высокий уровень вовлечения представителей целевой аудитории является четким доказательством простого факта: базовый вектор развития напрямую зависит от переосмысления внешнеэкономических политик. Банальные, но неопровержимые выводы, а также сторонники тоталитаризма в науке освещают чрезвычайно интересные особенности картины в целом, однако конкретные выводы, разумеется, функционально разнесены на независимые элементы.</p>
      </li>
      <li>
        <span>Будущее</span>
        <p>Но семантический разбор внешних противодействий обеспечивает актуальность новых предложений. </p>
        <p>Ристративных условий. И нет сомнений, что представители современных социальных резервов, вне зависимости от их уровня, должны быть разоблачены. Высокий уровень вовлечения представителей целевой аудитории является четким доказательством простого факта: базовый вектор развития напрямую зависит от переосмысления внешнеэкономических политик. Банальные, но неопровержимые выводы, а также сторонники тоталитаризма в науке освещают чрезвычайно интересные особенности картины в целом, однако конкретные выводы, разумеется, функционально разнесены на независимые элементы.</p>
      </li>
    </ul>
  </section>
  <section class="container official-partner">
    <div class="official-partner__content">
      <img src="img/official-partner.png" width="213" height="185" alt="" srcset="img/official-partner.png 1x, img/official-partner.png 2x">
      <div class="official-partner__text">
        <h2 class="section-title">Мы являемся официальным партнером Минпромторга</h2>
        <p>Но семантический разбор внешних противодействий обеспечивает актуальность новых предложений. Идейные соображения высшего порядка, а также базовый вектор развития выявляет срочную потребность новых предложений.</p>
        <p>Ристративных условий. И нет сомнений, что представители современных социальных резервов, вне зависимости от их уровня, должны быть разоблачены. Высокий уровень вовлечения представителей целевой аудитории является четким доказательством простого факта: базовый вектор развития напрямую зависит от переосмысления внешнеэкономических политик. Банальные, но неопровержимые выводы, а также сторонники тоталитаризма.</p>
      </div>
    </div>
  </section>
  <section class="container accreditations">
    <h2 class="section-title">Наши аккредитации</h2>
    <ul class="accreditations__list">
      <li>
        <p>Аттестат государственной аккредитации <br> RA.RU.10АД57 20.03.2017</p>
        <a href="#">Подробнее</a>
      </li>
      <li>
        <p>Лицензия Ростехнадзора <br> № ДЭ-00-016638 от 7 июня 2017 года</p>
        <a href="#">Подробнее</a>
      </li>
    </ul>
  </section>
  <section class="container advantages advantages--alt">
    <h2 class="section-title">Небольшой текст о том, что вы обладаете уникальными преимуществами</h2>
    <ul class="advantages__list">
      <li class="advantages__item">
        <svg width="60" height="60">
          <use xlink:href="#advantage-6"></use>
        </svg>
        <div>
          <h3>Лучшие тарифы на&nbsp;транспортировку товара из&nbsp;Китая и Индии</h3>
          <a href="transportation.html">Подробнее</a>
        </div>
      </li>
      <li class="advantages__item">
        <svg width="60" height="60">
          <use xlink:href="#advantage-4"></use>
        </svg>
        <div>
          <h3>Собственный отдел технических специалистов по регистрации медицинских изделий</h3>
          <a href="registration.html">Перейти в раздел</a>
        </div>
      </li>
      <li class="advantages__item">
        <svg width="60" height="60">
          <use xlink:href="#advantage-2"></use>
        </svg>
        <div>
          <h3>Аккредитованная испытательная лаборатория ТСЛ</h3>
          <a href="laboratories.html">О лаболатории</a>
        </div>
      </li>
    </ul>
  </section>
</template>

<style scoped lang="scss">

</style>
