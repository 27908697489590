import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ServiceView from '../views/ServiceView'
import ServiceList from '../views/ServiceList'
import ServiceHead from '../views/ServiceHead'
import BlogView from '../views/BlogView'
import BlogList from '../views/BlogList'
import EmployeeList from '../views/EmployeeList'
import RalView from "@/views/RalView.vue";
import PersonalView from "@/views/PersonalView.vue";
import ContentView from "@/views/ContentView.vue";
import CompanyView from "@/views/CompanyView.vue";
import ContactView from "@/views/ContactView.vue";
import ErrorPage from "@/views/ErrorPage.vue";
import PublishJob from "@/views/PublishJob.vue";
import AuthView from "@/views/AuthView.vue";
import tokenService from "@/services/token.service";
import IconView from "@/views/IconView.vue";
import CargoView from "@/views/CargoView.vue";
import RegistryLanding from "@/views/RegistryLanding.vue";
import CertificateLanding from "@/views/CertificateLanding.vue";
import MarketplaceLanding from "@/views/MarketplaceLanding.vue";

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {title: 'Компания Эвеланс, разработка документации'}
  },
  {
    path: '/order_registry',
    name: 'registryLanding',
    component: RegistryLanding,
    meta: {title: 'Расчет оформления регистрационного удостоверения Минздрава', layout: "LandingLayout"}
  },
  {
    path: '/order_certificate',
    name: 'certificateLanding',
    component: CertificateLanding,
    meta: {title: "Расчет оформления сертификатов и деклараций ТРТС", layout: "LandingLayout"}
  },
  {
    path: '/order_marketplace',
    name: 'marketplaceLanding',
    component: MarketplaceLanding,
    meta: {title: "Расчет оформления сертификатов и деклараций ТРТС", layout: "LandingLayout"}
  },
  {
    path: '/section',
    name: 'serviceHead',
    component: ServiceHead,
    meta: {title: 'Компания Эвеланс, заказ документов'}
  },
  {
    path: '/section/:alias',
    name: 'serviceList',
    component: ServiceList,
    meta: {title: 'Компания Эвеланс, сертификация и регистрационные удостоверения Минздрава'}
  },
  {
    path: '/section/:alias/:alias',
    name: 'service',
    component: ServiceView,
    meta: {title: 'Компания Эвеланс, заказываемый документ'}
  },
  {
    path: '/blog',
    name: 'blog',
    component: BlogList,
    meta: {title: 'Компания Эвеланс, новости, анонсы и другие что-то'},
  },
  {
    path: '/blog/:alias',
    name: 'blogOne',
    component: BlogView,
    meta: {title: 'Компания Эвеланс, новости, анонсы и другие что-то'},
  },
  {
    path: '/about/employee',
    name: 'employee',
    component: EmployeeList,
    meta: {title: 'Сотрудники компании, вакансии'}
  },
  {
    path: '/about/ral',
    name: 'ral',
    component: RalView,
    meta: { title: 'Компания Эвеланс, наши органы и лаборатории' }
  },
  {
    path: '/about/company',
    name: 'company',
    component: CompanyView,
    meta: { title: 'Компания Эвеланс, о компании' }
  },
  {
    path: '/about/contact',
    name: 'contact',
    component: ContactView  ,
    meta: { title: 'Компания Эвеланс, контакты' }
  },
  {
    path: '/about/cargo',
    name: 'cargo',
    component: CargoView,
    meta: { title: "Доставка грузов по всей России" }
  },
  {
    path: '/about',
    name: 'about',
    component: ContentView,
    meta: { title: 'Компания Эвеланс' },
  },
  {
    path: '/personal',
    name: 'personal',
    component: PersonalView,
    meta: { title: 'Личный кабинет. Компания Эвеланс' }
  },
  {
     path: '/auth',
    name: 'auth',
    component: AuthView,
    meta: { title: 'Авторизация' }
  },
  {
    path: '/publish-job/:slug',
    name: 'publishJob',
    component: PublishJob,
    meta: { title: 'Этапы работы'}
  },
  {
    path: '/icon-view',
    name: 'iconView',
    component: IconView,
    meta: { title: "Конфиг элементов" }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'notFound',
    component: ErrorPage,
    meta: { title: "404 Страница не найдена" }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { left: 0, top: 0 }
  }

})
const token = tokenService.read()
router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title}`;
  if(to.name === 'personal' && !token){
      next({name: 'auth'})
  }
  next();
});

export default router
