<script setup>

import IntroLandingRegistry from "@/components/IntroLandingRegistry.vue";
import {inject, defineEmits, ref, onMounted} from "vue";
import BenefitsBlock from "@/components/BenefitsBlock.vue";
import ButtonOrder from "@/components/ui/ButtonOrder.vue";
import sectionService from "@/services/section.service";


const emits = defineEmits(['open', 'doc'])
function openModal(){
  emits('open')
}

emits('doc', 'ru')

function changeTab(id) {
  tab.value = document.value.textBook.find(item => item.id === id)
}

const document = ref(false)
const loadDocument = async () => await sectionService.oneDocument("registration_medical_national_rules")

const modal = inject('stateModal')
function openModalOrder() {
  modal.value = {
    state: true,
    name: 'Консультация с лендинга РУ'
  }
}
const tab = ref({})

onMounted(async () => {
  document.value = await loadDocument()
  tab.value = document.value.textBook.find((item, key) => key === 0)
})

</script>

<template>
  <section class="container registryLanding">
    <IntroLandingRegistry @open="openModal"/>
    <div class="bookmark">
      <div :class="['bookmark--item', {active: item.id === tab.id}]" v-for="item in document.textBook" :key="item.id">
        <div @click="changeTab(item.id)" class="bookmark_nav button button--square">
          {{ item.bookmark }}
        </div>
      </div>
    </div>
    <div v-show="tab.content" class="content" v-html="tab.content">
    </div>
    <BenefitsBlock :title="'Почему нам доверяют:'" :direction="'registry'"/>
    <div class="center-block">
      <ButtonOrder @open="openModalOrder" :name="'Получить бесплатную консультацию по срокам'" :class-color="'button--red'" />
    </div>
  </section>
</template>

<style scoped lang="scss">
.center-block{
  text-align: center;
}
</style>
