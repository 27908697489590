<script setup>
import {inject, ref} from 'vue'
import {useYandexMetrika} from "yandex-metrika-vue3";


const yandexMetrika = useYandexMetrika()
const title = 'Аккредитованный орган сертификации'

const introTitle = 'Сертификация <span>продукции и услуг любой сложности</span>'

const modal = inject('stateModal')
function openModal(){
  yandexMetrika.reachGoal('openForm')
  modal.value = {
    state: true,
    name: 'Титул: заказать'
  }
}


const intro = ref(false)
window.addEventListener('scroll', function () {
  if (window.pageYOffset > 10) {
      intro.value = true
  } else {
     intro.value = false
  }
})

</script>

<template>
  <section :class="['intro', {'scrolled': intro}]">
    <div class="container intro__container">
      <p class="intro-text">{{ $t('title') }}</p>
      <h2 class="intro__title" v-html="introTitle"></h2>
      <button @click="openModal" class="button button--white" data-modal-open="contact-us">Заказать документ</button>
    </div>
  </section>
</template>

<style scoped lang="scss">

</style>
