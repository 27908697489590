<script setup>

</script>

<template>
  <section class="container">
      <div class="page-title">Л.К</div>
  </section>
</template>

<style scoped lang="scss">

</style>
